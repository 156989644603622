import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, Paper, Fade } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MachineList from './MachineList';
import useMachineScheduling, { Machine } from './hooks/useMachineScheduling';
import MachineScheduling from './MachineScheduling';
import PageTitle from '../../../../utils/globalComps/PageTitle';
import MachineDetails from './MachineDetails';


interface NewOrdersProps { }


const NewMachineScheduling: React.FC<NewOrdersProps> = () => {
	const theme = useTheme();
	const [selectedMachine, setSelectedMachine] = useState<Machine | null>(null);

	const { machines } = useMachineScheduling()

	const handleSelectMachine = (machine: Machine) => {
		setSelectedMachine(machine);
	};

	return (
		
		<Box sx={{ px: 2, py: 1 }}>
			<PageTitle title='Machine Scheduling' />
			<MachineList
				machines={machines}
				selectedMachine={selectedMachine}
				onSelectMachine={handleSelectMachine}
			/>
			<MachineDetails
				selectedMachine={selectedMachine}
			/>
			<Box sx={{ px: 0 }}>
				<MachineScheduling selectedMachine={selectedMachine} />
			</Box>
		</Box>
	);
};

export default NewMachineScheduling;