import React, {FC, useState, } from 'react'
import { AxiosResponse, AxiosError } from 'axios'
// mui icon imports
import Add from '@mui/icons-material/Add'
// custom imports
import { doAlert } from '../../../../utils/globalComps/PopupAlert/PopupAlert'
import { api, useEffectApi } from '../../../../utils/globals'
import { apiGet, downloadExportFile, getExportFilename } from '../../../../utils/api'
import PageTitle from '../../../../utils/globalComps/PageTitle'
import BaseContent from '../../../../utils/globalComps/BaseContent'
import MachineForm from './MachineForm'
import ArmForm from './ArmForm'
import CycleForm from './CycleForm'
import TableActions from '../../../../utils/table/TableActions'
import ApiTable from '../../../../utils/table/ApiTable'
import BaseAccordion from '../../../../utils/globalComps/BaseAccordion'
import Grid from '@mui/material/Grid'

interface MachinesProps {}
const Machines: FC<MachinesProps> = () => {
  const [factoryList, setFactoryList] = useState<string[]>([])
  const [factory, setFactory] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [machineList, setMachineList] = useState<any[]>([])
  const [machineId, setMachineId] = useState<number | undefined>(undefined)
  const [machineFormOpen, setMachineFormOpen] = useState<boolean>(false)
  const [armName, setArmName] = useState<string>('')
  const [armList, setArmList] = useState<any[]>([])
  const [cycleName, setCycleName] = useState<string>('')
  const [cycleList, setCycleList] = useState<any[]>([])
  const [factoryTemp, setFactoryTemp] = useState<number>(78)
  const [permissionList, setPermissionList] = useState<any>(undefined)

  const closeMachineForm = (submit: boolean, data: any, create: boolean) => {
    setLoading(true)
    setMachineFormOpen(false)

    apiGet('/inventory/machines', {}, (resp: any) => {
      setMachineList(resp.data.objects)
      setLoading(false)
    })
  }

  useEffectApi(() => {

    document.title = 'Machines | RotoEdgePro'

    apiGet('/inventory/machines', {}, (resp: any) => {
      setFactoryList(resp.data.factories)
      if (resp.data.factory)
        setFactory(resp.data.factory.name)
      setMachineList(resp.data.objects)
      setArmList(resp.data.arms)
      setCycleList(resp.data.cycles)
      setFactoryTemp(resp.data.time_diff_temp)
      setPermissionList(resp.data.permissions)
      setLoading(false)
    })

  }, [setLoading, setMachineList, setFactoryTemp, setArmList, setCycleList])

  // table actions
  const newMachine = () => {
    setMachineId(undefined)
    setMachineFormOpen(true)
  }
  const handleExport = (subUrl: string) => {

    apiGet(`/inventory/${subUrl}/`, {export: true, blob: true}, (resp: any) => {
      downloadExportFile(resp.data, getExportFilename(resp.headers))
    })

  }
  const handleExportMachines = () => handleExport('machines')
  const handleExportArms = () => handleExport('arms')
  const handleExportCycles = () => handleExport('cycles')

  const deleteMachine = (id: number) => {

    console.log('delete machine :', id)

    setLoading(true)
    const newData = [...machineList].filter((item: any) => item.id !== id)
    api.delete(`/inventory/machines/${id}/`)
      .then((resp: AxiosResponse) => {
        setMachineList(newData)
        setLoading(false)
      })
      .catch((err: AxiosError) => {
        if (err.response)
          doAlert(err.response.data.message, 'error', true)
      })
  }

  // Machine names list for machine select dropdown
  let machineOptions: any = []
  for (const machine of machineList) {
    machineOptions.push({value: machine.id, label: machine.name})
  }

  const setNameHelper = (dataList: any, prefix: string) => {
    return `${dataList[0].machine}-${prefix}${dataList.length+1}`
  }
  const getArmsCallback = (dataList: any) => setNameHelper(dataList, 'A')
  const getCyclesCallback = (dataList: any) => setNameHelper(dataList, 'C')

  const generateTableActions = () => {
    const tableActions: any = []

    if (permissionList?.create) tableActions.push({text: 'New Machine', icon: <Add />, action: newMachine})

    return tableActions
  }

  return (
    <>
      <PageTitle title='Machines' />
      <BaseContent loading={loading}>
        <div>
          <TableActions marginBottom={true} actions={generateTableActions()} />
          {machineList.sort((m1: any, m2: any) => m1.number - m2.number).map((machine: any, key: number) => (
            <BaseAccordion key={key} title={machine.name}>
              <Grid container>

                <Grid item xs={12}>
                  <ApiTable
                    objectName='Machine'
                    headers={[
                      {text: 'Factory', tooltip: ''}, {text: 'Name', tooltip: ''}, {text: 'Number', tooltip: ''},
                      {text: 'Arms/Shift', tooltip: 'Expected number of arms to be processed per shift'},
                      {text: 'Rounds/Shift (MRP)', tooltip: 'Rounds per shift is a user set number for the MRP calculations'},
											{ text: 'Logging Interval', tooltip: '' }, { text: 'Percent target', tooltip: '' }, 
											{ text: 'Order Balance Threshold', tooltip: 'Threshold of an order balance for when orders are considered `almost done` on this machine' },
                      {text: 'Hide available space', tooltip: ''}, {text: 'Disabled', tooltip: ''}, '']}
                    rowFields={['factory', 'name', 'number', 'arms_per_shift', 'rounds_per_shift', 'logging_interval',
											'percent_target', 'order_balance_threshold', 'hide_avail_space', 'disabled']}
                    dataField='object'
                    url={`/inventory/machines/${machine.id}/`}
                    editable
                    deletable
                    deleteFuncOverride={deleteMachine}
                    FormComponent={MachineForm}
                    formParams={{factories: factoryList, factory: factory}}
                  />
                </Grid>

                <Grid item xs={6}>
                  <ApiTable
                    tableName='Arms'
                    objectName='Arm'
                    headers={['Name', 'Capacity', 'Shutdown', 'Critical', '']}
                    rowFields={['name', 'capacity', 'shutdown', 'critical']}
                    dataField='objects'
                    refreshCallback={getArmsCallback}
                    url={`/inventory/arms/?machineId=${machine.id}`}
                    deleteUrlOverride='/inventory/arms/'
                    sortable
                    sortableUrl={{url: '/inventory/arms/', id: machine.id}}
                    orderable
                    editable
                    deletable
                    creatable
                    FormComponent={ArmForm}
                    formParams={{machines: machineOptions, machine: machine.id, name: armName}}
                  />
                </Grid>

                <Grid item xs={6}>
                  <ApiTable
                    tableName='Cycles'
                    objectName='Cycle'
                    headers={['Name', `(Factory < ${factoryTemp}°F)`, `(Factory > ${factoryTemp}°F)`, 'Temperature °F', '']}
                    rowFields={['name', 'hi_time', 'lo_time', 'temp']}
                    dataField='objects'
                    refreshCallback={getCyclesCallback}
                    url={`/inventory/cycles/?machineId=${machine.id}`}
                    sortable
                    sortableUrl={{url: '/inventory/cycles/', id: machine.id}}
                    orderable
                    editable
                    deletable
                    deleteUrlOverride='/inventory/cycles/'
                    creatable
                    FormComponent={CycleForm}
                    formParams={{machines: machineOptions, factoryTemp: factoryTemp, machine: machine.id,
                      name: cycleName}}
                  />
                </Grid>

              </Grid>

            </BaseAccordion>
          ))}
        </div>
      </BaseContent>
      <MachineForm id={machineId} open={machineFormOpen} closeForm={closeMachineForm} factories={factoryList} factory={factory} />
    </>
  )
}

export default Machines
