import React, { FC, useState } from 'react'
// mui
import Menu from '@mui/material/Menu'
// mui icons
import BaseTooltip from './BaseTooltip'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Button from '@mui/material/Button'
import ExpandMore from '@mui/icons-material/ExpandMore'


interface DropdownActionProps {
  id: number
  name: string
  text: string
  action: (id: number, name: string) => void
  icon?: any | undefined
  tooltip?: string | undefined
}
/**
 * Individual dropdown action
 * @param id id to use action on/with
 * @param name
 * @param text
 * @param action
 * @param icon
 * @param tooltip
 */
const DropdownAction: FC<DropdownActionProps> = ({id, name, text, action, icon, tooltip}) => {
  return (
    <BaseTooltip text={tooltip} placement='right'>
      <MenuItem onClick={() => action(id, name)} sx={{fontSize: '10px'}}>
        {icon ? <ListItemIcon sx={{fontSize: 'inherit', minWidth: '32px'}}>{icon}</ListItemIcon> : <></>}
        <ListItemText>{text}</ListItemText>
      </MenuItem>
    </BaseTooltip>
  )
}

interface DropdownActionsProps {
  name?: string,
  children: any
  setExpandedId?: (id: number | undefined) => void
  id?: number
  noMin?: boolean | undefined
}
/**
 * DropdownActions comp
 * @param name?
 * @param children
 * @param setExpandedId?
 * @param id?
 * @param noMin? removes the minimum height on the actions button (for places where button size can be larger)
 */
const DropdownActions: FC<DropdownActionsProps> = ({name, children, setExpandedId, id,
                                                     noMin}) => {
  const [anchorEl, setAnchorEl] = useState<undefined | null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (setExpandedId) setExpandedId(id)
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        variant='contained'
        endIcon={<ExpandMore sx={noMin === true ? {} : {fontSize: 13}} />}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={noMin === true ? {borderRadius: 3} : {maxHeight: '27px', maxWidth: '100px', fontSize: 13, borderRadius: 3}}
      >
        {name ? name : 'Actions'}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClick={handleClose}
        onClose={handleClose}
				slotProps={{
					paper: {
						sx: {
							mt: 1,
							borderRadius: 3
						}
					}
				}}
      >
        {children}
      </Menu>
    </>
  )
}

export default DropdownActions
export { DropdownAction }
