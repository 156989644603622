import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Box, Typography, useTheme, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

interface FinancialData {
	non_conforming_cost: number;
	revenue: number;
}

interface ScrapSalesChartProps {
	scrapSales: FinancialData;
	height: number;
}

const lightenColor = (hex: string, percent: number) => {
	let r = parseInt(hex.slice(1, 3), 16);
	let g = parseInt(hex.slice(3, 5), 16);
	let b = parseInt(hex.slice(5, 7), 16);

	r = Math.min(255, Math.floor(r + (255 - r) * percent / 100));
	g = Math.min(255, Math.floor(g + (255 - g) * percent / 100));
	b = Math.min(255, Math.floor(b + (255 - b) * percent / 100));

	return `rgb(${r}, ${g}, ${b})`;
};

const ScrapSalesChart: React.FC<ScrapSalesChartProps> = ({ scrapSales, height }) => {
	const theme = useTheme();
	const { non_conforming_cost, revenue } = scrapSales;
	const percentage = ((non_conforming_cost / revenue) * 100).toFixed(2);

	const chartOptions: ApexOptions = {
		chart: {
			type: 'donut',
		},
		labels: ['Scrap Cost', 'Remaining Revenue'],
		colors: [theme.palette.error.main, theme.palette.primary.main],
		legend: {
			position: 'bottom',
			labels: {
				colors: theme.palette.text.primary,
			},
		},
		plotOptions: {
			pie: {
				donut: {
					size: '60%',
					labels: {
						show: false,
						total: {
							show: true,
							label: 'Scrap % of Revenue',
							formatter: () => `${percentage}%`,
							color: theme.palette.text.primary,
						},
					},
				},
				dataLabels: {
					minAngleToShowLabel: 0, // Ensure labels are shown regardless of slice size
				},
			},
		},
		dataLabels: {
			enabled: true,
			style: {
				fontSize: '28px',
				colors: [
					// theme.palette.common.white
					lightenColor(theme.palette.error.main, 20),
					lightenColor(theme.palette.primary.main, 20)
				]
			},
			background: {
				enabled: true,
				foreColor: theme.palette.common.white,
				borderRadius: 2,
				padding: 4,
				opacity: 1,
				dropShadow: {
					enabled: true,
					top: 1,
					left: 1,
					blur: 1,
					opacity: 0.45
				}
			}
		},
		tooltip: {
			y: {
				formatter: (val: number) => `$${val.toLocaleString()}`,
			},
		},
	};

	const series = [non_conforming_cost, Math.max(0, (revenue - non_conforming_cost))];

	return (
		<Box>
			<Box display="flex" justifyContent='center' alignItems="center" mb={2}>
				<Typography variant="h6" component="div" mr={1}>
					Scrap Cost vs Revenue
				</Typography>
				<Tooltip title="This chart displays the total revenue compared to the amount lost due to scrap on machines.">
					<IconButton size="small">
						<InfoIcon />
					</IconButton>
				</Tooltip>
			</Box>
			<ReactApexChart
				options={chartOptions}
				series={series}
				type="donut"
				height={height}
			/>
		</Box>
	);
};

export default ScrapSalesChart;
