import { useEffect, useMemo, useState } from 'react';
import useWebsocket from '../../../../../websocket/hooks/useWebsocket';

// Define the Preferences interface to include all the fields from your model
export interface Preferences {
	enable_email_notifications: boolean,
	enable_sms_notifications: boolean,
	enable_inbound_sync_notifications: boolean,
	enable_outbound_sync_notifications: boolean,
	enable_item_scrap_notifications: boolean,
	consecutive_scrap: number,
	enable_order_scrap_rate_notifications: boolean,
	scrap_threshold: number,
	scrap_rate_sensitivity: number,
	enabled_logging_interval_notifications: boolean,
	enable_skipped_arm_notifications: boolean,
}

export interface NotificationPreferences {
	preferences: Preferences;
}

const useNotificationPreferences = () => {
	const [notificationPreferences, setNotificationPreferences] = useState<NotificationPreferences>({
		preferences: {
			enable_email_notifications: false,
			enable_sms_notifications: false,
			enable_inbound_sync_notifications: false,
			enable_outbound_sync_notifications: false,
			enable_item_scrap_notifications: false,
			consecutive_scrap: 2,
			enable_order_scrap_rate_notifications: false,
			scrap_threshold: 5.0,
			scrap_rate_sensitivity: 2,
			enabled_logging_interval_notifications: false,
			enable_skipped_arm_notifications: false,
		},
	});

	const { sendMessage } = useWebsocket('/main/notification_preferences/', setNotificationPreferences);

	// Use useMemo to memoize the preferences object
	const preferences = useMemo(() => notificationPreferences.preferences, [notificationPreferences]);

	return { preferences, sendMessage };
};

export default useNotificationPreferences;
