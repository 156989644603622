import React, { FC, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography, { TypographyTypeMap } from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ExpandMore from '@mui/icons-material/ExpandMore';
import { getAccordionBackgroundColor } from "../globals";
import { Box } from '@mui/material';

interface BaseAccordionProps {
	title: string;
	onChange?: (event: React.SyntheticEvent, expanded: boolean) => void;
	variant?: TypographyTypeMap['props']['variant'];
	size?: string;
	enabled?: boolean | undefined;
	expanded?: boolean | undefined;
	light?: boolean | undefined;
	children: JSX.Element | JSX.Element[];
	sx?: any;
}

const BaseAccordion: FC<BaseAccordionProps> = ({
	title,
	onChange,
	variant,
	size,
	enabled = true,
	expanded,
	light,
	children,
	sx
}) => {
	const theme = useTheme();
	const backgroundColor = getAccordionBackgroundColor(theme, light);

	const getAccordionSx = () => {
		const baseSx = {
			backgroundColor,
			boxShadow: 3,
			position: 'relative', // Add this to ensure proper stacking
			'&.MuiAccordion-root': {
				borderRadius: '16px',
				'&:before': {
					display: 'none',
				},
				'&:first-of-type': {
					borderTopLeftRadius: '16px',
					borderTopRightRadius: '16px',
				},
				'&:last-of-type': {
					borderBottomLeftRadius: '16px',
					borderBottomRightRadius: '16px',
				},
				'&.Mui-expanded': {
					margin: '4px 0', // Add vertical margin when expanded
				},
				'&:not(.Mui-expanded)': {
					margin: '4px 0', // Add consistent margin for non-expanded state
				},
			},
			...sx
		};

		if (size === 'sm') {
			return {
				...baseSx,
				'& .MuiAccordionSummary-root': {
					minHeight: '40px',
					'&.Mui-expanded': {
						minHeight: '40px',
					}
				}
			};
		}

		return baseSx;
	};

	if (!enabled) {
		return <Box sx={{ mt: 2 }}>{children}</Box>;
	}

	return (
		<Accordion
			sx={getAccordionSx()}
			onChange={onChange}
			expanded={expanded}
			// TransitionProps={{ unmountOnExit: true }}
		>
			<AccordionSummary
				expandIcon={<ExpandMore />}
				sx={{
					'&.MuiAccordionSummary-root': {
						borderRadius: expanded ? '16px 16px 0 0' : '16px',
					}
				}}
			>
				<Grid container spacing={0} alignItems='center' justifyContent='center'>
					<Typography variant={variant || 'h5'}>{title}</Typography>
				</Grid>
			</AccordionSummary>
			<AccordionDetails>
				{children}
			</AccordionDetails>
		</Accordion>
	);
};

export default BaseAccordion;