import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Grid, Box, useTheme } from '@mui/material';
import { Machine } from './hooks/useMachineScheduling';
import SemiCircleGauge from '../../dashboard/charts/SemiCircleGauge';
import RadialCircle from '../../dashboard/charts/RadialCircle';

interface MachineDetailsProps {
	selectedMachine: Machine | null;
}

const MachineDetails: React.FC<MachineDetailsProps> = ({ selectedMachine }) => {
	const theme = useTheme();

	if (!selectedMachine || !selectedMachine.standalone_scheduled_orders) {
		return null;
	}

	const hasOrders = selectedMachine.standalone_scheduled_orders.length > 0;

	return (
		<Grid container spacing={0} sx={{ mt: 2, mb: 2, p: 2, borderRadius: 3, boxShadow: 3 }}>
			<Grid item xs={12} md={7} >
				<TableContainer component={Paper} sx={{ height: '100%', borderRadius: 3, boxShadow: 2 }}>
					<Typography variant="h6" sx={{ px: 2, py: 1 }}>Orders Near Completion</Typography>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell sx={{ fontWeight: 'bold' }} >Arm</TableCell>
								<TableCell>Number</TableCell>
								<TableCell>Item</TableCell>
								<TableCell align="center">Quantity</TableCell>
								<TableCell align="center">Balance</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{hasOrders ? (
								selectedMachine.standalone_scheduled_orders.map((order, index) => (
									<TableRow key={index}>
										<TableCell sx={{ fontWeight: 'bold' }}>{order.arm}</TableCell>
										<TableCell>{order.number}</TableCell>
										<TableCell>{order.item}</TableCell>
										<TableCell align="center">{order.quantity}</TableCell>
										<TableCell align="center">{order.balance}</TableCell>
									</TableRow>
								))
							) : (
								<TableRow>
									<TableCell colSpan={5} align="center">No orders near completion</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<Grid item xs={12} md={5} mt={2}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
							<SemiCircleGauge
								value={selectedMachine.productivity}
								label="Productivity"
								height={275}
							/>
						</Box>
					</Grid>
					<Grid item xs={6}>
						<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
							<SemiCircleGauge
								value={selectedMachine.capacity}
								label="Utilized Capacity"
								height={275}
							/>
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default MachineDetails;