import React, { FC, useState, useEffect } from 'react'
// mui imports
import Grid from '@mui/material/Grid'
// custom imports
import { api, FormSpacing } from '../../../../utils/globals'
import { AxiosError, AxiosResponse } from 'axios'
import { doAlert } from '../../../../utils/globalComps/PopupAlert/PopupAlert'
import FormInputText from '../../../../utils/form/FormInputText'
import FormInputNumber from '../../../../utils/form/FormInputNumber'
import FormInputDropdown from '../../../../utils/form/FormInputDropdown'
import FormInputAutocomplete from '../../../../utils/form/FormInputAutocomplete'
import FormInputSwitch from '../../../../utils/form/FormInputSwitch'
import ApiForm from '../../../../utils/form/ApiForm'
import ImportModal from '../../../../utils/modals/ImportModal/ImportModal'
import Delete from '@mui/icons-material/Delete'
import FileUpload from '@mui/icons-material/FileUpload'

const ASSEMBLY: number = 2
const POWDER: number = 3
const COMPONENT: number = 4
const KIT: number = 5

interface ItemFormProps {
  id: number | undefined
  open: boolean
  closeForm: (submit: boolean) => void
  molds: any[]
  types: string[]
  type: number
  foamingEnabled?: boolean
  hasPdf?: boolean
  deleteProcessInstructions: (id: number) => void
  setNewProcessInstructions: (file: any) => void
  costSalesPermission: boolean
}
const ItemForm: FC<ItemFormProps> = ({id, open, closeForm, molds,
                                      types, type, foamingEnabled,
                                      hasPdf, setNewProcessInstructions, deleteProcessInstructions,
                                      costSalesPermission}) => {

  const fetchUrl: string = id ? `/inventory/items/${id}/` : ''
  const submitUrl: string = '/inventory/items/'

  const [selectedType, setSelectedType] = useState<number | undefined>(undefined)
  const [pdfUploadOpen, setPdfUploadOpen] = useState<boolean>(false)
  const [pdfUploadModalTitle, setPdfUploadModalTitle] = useState<string>('')
  const [isFoam, setIsFoam] = useState<boolean>(false)

  const typeChange = (e: any) => setSelectedType(e.target.value)
  const isFoamChange = (e: any) => {
    setIsFoam(e.target.value === 'true')
  }

  useEffect(() => {

    setSelectedType(type)

  }, [foamingEnabled, molds, types])

  // rows
  const topFormRow = () => {
    let row: any[] = [
      {name: 'name', xs: 4, input: <FormInputText name='name' label='Item Name' />},
      {name: 'type', xs: 4, input: <FormInputDropdown name='type' label='Type' options={types} overrideValue={selectedType} myOnChange={typeChange} />},
      {name: 'mold', xs: 4, input: <FormInputAutocomplete name='mold' label='Mold' options={molds} />},
    ]

    if (selectedType && [ASSEMBLY, KIT, POWDER, COMPONENT].includes(selectedType)) {
      // remove mold field
      row = row.filter((entry: any) => entry.name !== 'mold')
      row.map((entry: any) => entry['xs'] = 6)
    }

    return row
  }
  const midFormRow = (isFoam: boolean) => {
    let row: any[] = [
      {name: 'description', xs: 8, input: <FormInputText name='description' label='Description' />},
      {name: 'takt_time', xs: isFoam ? 2 : 4, input: <FormInputNumber name='takt_time' label='Line Plan sTakt Time' float />},
    ]

    if (isFoam)
      row.push({name: 'foam_takt_time', xs: 2, input: <FormInputNumber name='foam_takt_time' label='Foam Takt Time' float disabled={isFoam} />})

    // edit form fields based on item type
    if (selectedType && (selectedType === ASSEMBLY || selectedType === KIT)) {
      // remove foam fields
      row = row.filter((entry: any) => entry.name !== 'foam_takt_time')
    } else if (selectedType && (selectedType === POWDER || selectedType === COMPONENT)) {
      // remove takt time field
      row = row.filter((entry: any) => entry.name !== 'takt_time')
      row.map((entry: any) => entry['xs'] = 12)
      // remove foam fields
      row[0].xs = 12
      row = row.filter((entry: any) => entry.name !== 'foam_takt_time')

      // add bin location
      if (selectedType === COMPONENT) {
        row[0].xs = 9
        row.push({name: 'bin_location', xs: 3, input: <FormInputText name='bin_location' label='Bin Location' />})
      }
    }

    if (!foamingEnabled) {
      row[0].xs = 10
      row = row.filter((entry: any) => entry.name !== 'foam_takt_time')
    }

    return row
  }
  const botFormRow = () => {
    let row: any[] =[
      {name: 'first_article', xs: 6, input: <FormInputSwitch name='first_article' label='First Article' />},
      {name: 'is_foam', xs: 6, input: <FormInputSwitch name='is_foam' label='Foamed' onChange={isFoamChange} />},
    ]

    // remove is foam if not moldable
    if (selectedType && [ASSEMBLY, KIT, POWDER, COMPONENT].includes(selectedType)) {
      row[0].xs = 12
      row = row.filter((entry: any) => entry.name !== 'is_foam')
    }

    if (!foamingEnabled) {
      row[0].xs = 12
      row = row.filter((entry: any) => entry.name !== 'is_foam')
    }

    return row
  }
  const costSalesRow = () => {
    let row: any[] = costSalesPermission ? [
      {name: 'cost', xs: 6, input: <FormInputNumber name='unit_cost' label='Item Cost' float />},
      {name: 'sale', xs: 6, input: <FormInputNumber name='sales_price' label='Item Sale Price' float />}
    ] : []

    return row
  }

  // Process instructions management
  const openImportModal = () => {
    api.get(fetchUrl)
      .then((resp: AxiosResponse) => {
        setPdfUploadModalTitle(`Process Instructions Upload ${resp.data.object[0].name}`)
        setPdfUploadOpen(true)
      })
      .catch((err: AxiosError) => {if (err.response) doAlert(err.response.data.message, 'error', open)})
  }
  const closeImportModal = (uploaded: boolean, file: any) => {
    if (uploaded)
      setNewProcessInstructions(file)
    setPdfUploadOpen(false)
  }
  const generateTableActions = () => {
    let actionList: any = [{text: 'Upload Process Instructions', action: openImportModal, icon: <FileUpload />}]
    if (hasPdf && id)
      actionList.push({text: 'Delete Process Instructions', action: () => deleteProcessInstructions(id), icon: <Delete />})
    return actionList
  }

  // Overwrites type value because it is stuck to the item table selected type for some reason otherwise
  const handleSubmit = (data: any) => {
    console.log('new data :', {...data, type: selectedType})
    return {...data, type: selectedType}
  }

  return (
    <>
      <ApiForm
        name='Item Form'
        open={open}
        defaultValues={{name: '', type: selectedType, mold_id: '', description: '', takt_time: '',
          is_foam: false, foam_takt_time: '', printing_enabled: false, skip_secondary: false,
          first_article: true}}
        closeForm={closeForm}
        id={id}
        tableActions={[]}
        fetchUrl={fetchUrl}
        submitUrl={submitUrl}
        editFlag
        submitCallback={handleSubmit}
      >
        {[topFormRow(), midFormRow(isFoam), botFormRow(), costSalesRow()].map((row: any, key: any) => (
          <Grid container spacing={FormSpacing} key={key} sx={{pt: key === 1 ? '10px' : ''}}>
            {row.map((entry: any, key: number) => (
              <Grid key={key} item xs={entry.xs}>{entry.input}</Grid>
            ))}
          </Grid>
        ))}
      </ApiForm>
      <ImportModal open={pdfUploadOpen} closeHandler={closeImportModal}
                   url={fetchUrl} title={pdfUploadModalTitle} typeName='pdf'
                   appendData={{type: 'process_instructions'}} doCheck />
    </>
  )
}

export default ItemForm
