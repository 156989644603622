import { Theme } from '@mui/material/styles';

export const getStyles = (theme: Theme) => ({
	stickyColumnHeaderStyle: {
		position: 'sticky',
		left: 0,
		backgroundColor: theme.palette.table.stickyHeader,
		zIndex: 4,
		pl: 4,
		fontWeight: 'bold',
		borderRight: `1px solid ${theme.palette.divider}`,
		textAlign: 'center',
	},

	stickyColumnStyle: {
		position: 'sticky',
		left: 0,
		backgroundColor: 'inherit',
		zIndex: 2,
		width: {
			xs: '100px',
			sm: '150px',
			md: '200px',
		},
		textAlign: 'center',
		fontWeight: 'bold',
		borderRight: `1px solid ${theme.palette.divider}`,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},

	stickyHeaderStyle: {
		position: 'sticky',
		top: 0,
		backgroundColor: theme.palette.table.stickyHeader,
		zIndex: 3,
	},

	cellStyle: {
		width: {
			xs: '100px',
			sm: '150px',
			md: '200px',
		},
		textAlign: 'center',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},

	unitStyle: {
		width: {
			xs: '50px',
			sm: '50px',
			md: '50px',
		},
		textAlign: 'center',
	},

	machineChipStyle: {
		backgroundColor: theme.palette.color.blue,
		margin: '2px',
	},

	armChipStyle: {
		backgroundColor: theme.palette.color.yellow,
		margin: '2px',
	},

	loadedChipStyle: {
		backgroundColor: theme.palette.color.green,
		margin: '2px',
	},

	lineplanChipStyle: {
		backgroundColor: theme.palette.color.purple,
		margin: '2px',
	},

	nonConformingStyle: {
		color: theme.palette.mode == 'dark' ? theme.palette.error.light : theme.palette.error.main,
		fontWeight: 'bold',
	},
});

export type OrderTableStyles = ReturnType<typeof getStyles>;