import React, {FC, useState} from 'react'
// mui imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import OverrideFormModal from "../OverrideForm/OverrideFormModal";
import LoginFormModal from "../LoginForm/LoginFormModal";
import {useEffectApi} from "../../globals";
import BaseContent from "../BaseContent";
import Paper from "@mui/material/Paper";
import {apiGet} from "../../api";
import OverallProgressBar from "../ProductivityProgress/OverallProgressBar";
import MachineProgress from "../ProductivityProgress/MachineLoggedProgress";
import LineplanProgress from "../ProductivityProgress/LineplanLoggedProgress";
// custom imports


interface ProcessingUserActionsProps {
  productivityEnabled: boolean
  machineId?: number
  lineplanId?: number
  forceRefresh?: boolean
  canView?: boolean | undefined
}
const ProcessingUserActions: FC<ProcessingUserActionsProps> = ({productivityEnabled, machineId,
                                                                 lineplanId, forceRefresh,
                                                                 canView}) => {

  const [loading, setLoading] = useState<boolean>(true)
  const [overrideFormOpen, setOverrideFormOpen] = useState<boolean>(false)
  const [loginFormOpen, setLoginFormOpen] = useState<boolean>(false)
  const [login, setLogin] = useState<boolean>(false)
  const [loginRefresh, setLoginRefresh] = useState<boolean>(false)
  const [currentShift, setCurrentShift] = useState<any>({})

  useEffectApi(() => {
    // console.log('ProcessingUserActions loading:', loading, forceRefresh)
    apiGet('/main/currentshift/', {}, (resp: any) => {
      setCurrentShift(resp.data)
      setLoading(false)
    })
  }, [productivityEnabled, machineId, lineplanId, forceRefresh])

  // actions
  const openOverride = () => setOverrideFormOpen(true)
  const closeOverride = () => setOverrideFormOpen(false)

  const openLoginForm = (login: boolean) => {
    setLogin(login)
    setLoginFormOpen(true)
  }

  const closeLoginForm = () => {
    setLoginFormOpen(false)
    setLoginRefresh(!loginRefresh)
  }

  return (
    <>
      <BaseContent sx={{ p: 0, mx: 0, mt: 0, mb: 1 }} loading={loading}>
        {productivityEnabled ?
          <Grid container spacing={0} component={Paper} sx={{ borderRadius: 3 }}>
            {canView === undefined || canView ?
              <Grid container item xs={12} spacing={2} sx={{ p: 2 }}>
                <OverallProgressBar machineId={machineId} lineplanId={lineplanId} shiftId={currentShift.id} refresh={forceRefresh} />
                {machineId !== undefined ? <MachineProgress id={machineId} shiftId={currentShift.id} refresh={forceRefresh}
                                                            extraRefresh={loginRefresh} /> : <></>}
                {lineplanId !== undefined ? <LineplanProgress id={lineplanId} shiftId={currentShift.id} refresh={forceRefresh}
                                                              extraRefresh={loginRefresh} /> : <></>}
              </Grid>
              :
              <></>
            }
          </Grid>
          :
          <></>
        }
      </BaseContent>

      <Grid container item xs={12} spacing={1}>
        {productivityEnabled ?
          <>
            <Grid item><Button sx={{ borderRadius: 3 }} variant='contained' onClick={() => openLoginForm(true)}>Login</Button></Grid>
						<Grid item><Button sx={{ borderRadius: 3 }} variant='contained' onClick={() => openLoginForm(false)}>Logout</Button></Grid>
          </>
          :
          <></>}
				<Grid item sx={{ ml: 'auto' }}><Button sx={{ borderRadius: 3 }} variant='contained' color='primary' onClick={openOverride}>Override</Button></Grid>
      </Grid>
      <OverrideFormModal open={overrideFormOpen} onClose={closeOverride} />
      <LoginFormModal open={loginFormOpen} onClose={closeLoginForm} login={login} machineId={machineId}
                      lineplanId={lineplanId} />
    </>
  )
}

export default ProcessingUserActions
