import { FC, useMemo } from "react";
import { Table, Column } from '../../../../../websocket/tables/Table';
import { LineplanProcessedOrder, MachineProcessedOrder } from "../hooks/Interfaces";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface OrdersProcessedProps {
	orderData: MachineProcessedOrder[] | LineplanProcessedOrder[]
	view: 'molding' | 'post_molding' | 'assembly'
}

const OrdersProcessed: FC<OrdersProcessedProps> = ({ orderData, view }) => {
    const columns: { [key: string]: Column[] } = {

			molding: [
				{
					header: 'Order',
					value: 'order'
				},
				{
					header: 'Item',
					value: 'item'
				},
				{
					header: 'Ship Date',
					value: 'ship_date'
				},
				{
					header: 'Balance', headerAlign: 'center',
					value: 'balance', valueAlign: 'center'
				},
				{
					header: 'Conforming Parts', headerAlign: 'center',
					value: 'conforming_parts', valueAlign: 'center'
				},
				{
					header: 'Non Conforming Parts', headerAlign: 'center',
					value: 'non_conforming_parts', valueAlign: 'center'
				},
				{
					header: 'Skipped Count', headerAlign: 'center',
					value: 'skipped_count', valueAlign: 'center'
				},
			], 

			post_molding: [
				{
					header: 'Order',
					value: 'order'
				},
				{
					header: 'Item',
					value: 'item'
				},
				{
					header: 'Ship Date',
					value: 'ship_date'
				},
				{
					header: 'Balance', headerAlign: 'center',
					value: 'balance', valueAlign: 'center'
				},
				{
					header: 'Conforming Parts', headerAlign: 'center',
					value: 'conforming_parts', valueAlign: 'center'
				},
				{
					header: 'Non Conforming Parts', headerAlign: 'center',
					value: 'non_conforming_parts', valueAlign: 'center'
				}
			], 

			assembly: [
				{
					header: 'Order',
					value: 'order'
				},
				{
					header: 'Item',
					value: 'item'
				},
				{
					header: 'Ship Date',
					value: 'ship_date'
				},
				{
					header: 'Balance', headerAlign: 'center',
					value: 'balance', valueAlign: 'center'
				},
				{
					header: 'Conforming Parts', headerAlign: 'center',
					value: 'conforming_parts', valueAlign: 'center'
				},
				{
					header: 'Non Conforming Parts', headerAlign: 'center',
					value: 'non_conforming_parts', valueAlign: 'center'
				}
			], 
		};

    const rows = useMemo(() => orderData ?? [], [orderData]);

    return (
			<Accordion
				sx={{
					borderRadius: '12px !important',
					'&:before': {
						display: 'none', // Removes the default divider
					},
					'& .MuiAccordion-region': {
						borderBottomLeftRadius: '12px',
						borderBottomRightRadius: '12px',
					}
				}}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="orders-processed-content"
					id="orders-processed-header"
					sx={{
						marginY: 0,
						// borderBottom: 1,
						borderColor: 'rgb(128, 128, 128, 0.2)',
						borderTopLeftRadius: '12px',
						borderTopRightRadius: '12px',
						'&.Mui-expanded': {
							borderRadius: 0, // Remove border radius when expanded
						}
					}}
				>
					<Typography variant='h6' marginY={0}>Processed Orders</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ padding: 0 }}>
					<Table
						columns={columns[view]}
						rows={rows}
						sx={{
							borderTopLeftRadius: 0,
							borderTopRightRadius: 0,
							borderBottomLeftRadius: '12px',
							borderBottomRightRadius: '12px'
						}}
					/>
				</AccordionDetails>
			</Accordion>
    );
}

export default OrdersProcessed;
