import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import {
  getOrdersWithStartDates,
  mapToCounterWeightsModel,
  mapToHardBreaksModel,
  mapToOrderModel
} from '../../../components/scheduler/lib/Util'
import UnassignedStore from '../../../components/scheduler/lib/UnassignedStore'
import { useTheme } from '@mui/material/styles'
// mui imports
import IconButton from "@mui/material/IconButton";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
// mui icons
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline'
import ScheduleSend from '@mui/icons-material/ScheduleSend'
import Edit from "@mui/icons-material/Edit";
// custom imports
import { colors } from '../../colors'
import {getSubScheduledToArm, saveRotoSchedule, setMoldLoadCount, unscheduleFromArm} from './RotoSchedulerApi'
import { Actions } from '../../table/BaseRow'
import { doConfirm } from '../../modals/Confirm'
import { assignToArm } from '../../../components/pages/scheduling/MachineScheduling/MachineSchedulingApi'
import { useEffectApi } from '../../globals'
import Scheduler from '../../../components/scheduler/Scheduler'
import SchedulerDragContainer from '../../../components/scheduler/SchedulerDragContainer'
import BaseContent from '../BaseContent'
import AssignToArm from '../../modals/AssignToArm'
import SetupsheetViewHelper from '../../../components/pages/management/setupsheets/SetupsheetViewHelper'
import BaseTooltip from '../BaseTooltip'
import UpdateMoldCountModal from "./UpdateMoldCountModal";
import { DEFAULT_WORKING_TIME } from '../../../components/scheduler/lib/SchedulerConfig';


interface ArmScheduleRowProps {
  armChoices: any[]
  armScheduledOrder: any
  refreshTable: () => void
  canEdit?: boolean | undefined
  canDelete?: boolean | undefined
  openSetupsheetView: (itemName: string) => void
  doUpdateMoldCount: (id: number, quantity: number) => void
}
const ArmScheduleRow: FC<ArmScheduleRowProps> = ({armChoices, armScheduledOrder, refreshTable, canEdit,
                                                   canDelete, openSetupsheetView, doUpdateMoldCount}) => {

  const [assignArmId, setAssignArmId] = useState<number | undefined>(undefined)

  const theme = useTheme()
  const rowColor: string = theme.palette.color.yellow

  useEffect(() => {
    // console.log('arm scheduled row use effect')
  }, [armScheduledOrder])

  const doUnscheduleFromArm = (scheduledArmOrderId: number) => {
    doConfirm(`Unschedule ${armScheduledOrder.work_order_number} ${armScheduledOrder.item} from ${armScheduledOrder.arm}?`,
    () => unscheduleFromArm(scheduledArmOrderId, () => refreshTable()))
  }

  const openAssignToArmModal = () => {
    setAssignArmId(armScheduledOrder.arm_scheduled_id)
  }
  const closeAssignToArmModal = () => {
    setAssignArmId(undefined)
  }
  const doAssignToArm = (orderItemId: number | undefined, assignData: any, machineId: number | undefined,
                         callback: (data: any) => void) => {
    assignToArm(orderItemId, assignData, true, (data: any) => {
      callback(data)
      if (data.status === 'success') {
        closeAssignToArmModal()
        refreshTable()
      }
    })
  }

  const handleUpdateMoldCount = (order: any) => {
    doUpdateMoldCount(order.arm_scheduled_id, order.mold_load_quantity)
  }

  const rowActions: any[] = []
  if (canEdit)
    rowActions.push({icon: <ScheduleSend />, text: 'Move to different Arm', action: openAssignToArmModal})

  if (canDelete)
    rowActions.push({icon: <RemoveCircleOutline />, text: 'Unschedule from Arm', action: doUnscheduleFromArm})

  return (
    <>
      <TableRow sx={{backgroundColor: rowColor}} data-id={armScheduledOrder.id} className='scheduler-unplanned-item'>
        <TableCell align='center'>
          {armScheduledOrder.work_order_number}
        </TableCell>
        <TableCell align='center'>
          <BaseTooltip text='View Setupsheet'>
            <Button onClick={() => openSetupsheetView(armScheduledOrder.item)}>
              {armScheduledOrder.item}
            </Button>
          </BaseTooltip>
        </TableCell>
        <TableCell align='center'>
          {armScheduledOrder.description}
        </TableCell>
        <TableCell align='center'>
          {armScheduledOrder.arm}
        </TableCell>
        <TableCell align='center'>
          {armScheduledOrder.ship_date}
        </TableCell>
        <TableCell align='center'>
          {armScheduledOrder.balance}
        </TableCell>
        <TableCell align='center'>
          {armScheduledOrder.mold_load_status}
        </TableCell>
        <TableCell align='center' onClick={() => handleUpdateMoldCount(armScheduledOrder)} sx={{cursor: 'pointer'}}>
          {armScheduledOrder.mold_load_quantity}
          {canEdit ?
            <BaseTooltip text='Edit mold count' placement='right'>
              <IconButton size='small'><Edit sx={{fontSize: '15px', ml: '5px'}} /></IconButton>
            </BaseTooltip>
            :
            <></>
          }
        </TableCell>
        <TableCell align='center'>
          {armScheduledOrder.mold_volume}
        </TableCell>
        <TableCell align='center'>
          {armScheduledOrder.oven_temp}
        </TableCell>
        <TableCell align='center'>
          {armScheduledOrder.oven_time}
        </TableCell>
        <TableCell align='center'>
          {armScheduledOrder.takt_time}
        </TableCell>
        {rowActions.length > 0 ?
          <Actions
            id={armScheduledOrder.arm_scheduled_id}
            actions={rowActions}
          />
          :
          <TableCell> </TableCell>
        }
      </TableRow>

      <AssignToArm advancedScheduling={true} orderItemId={assignArmId}
                   onClose={closeAssignToArmModal} onAssign={doAssignToArm} move={true} />
    </>
  )
}

interface RotoSchedulerProps {
  armChoices: any[]
  machineName?: string
  armId: number
  refresh: boolean
  readOnly: boolean
  canEdit?: boolean | undefined
  canDelete?: boolean | undefined
  loadToArmAction?: (data: any, cb: () => void) => void
}
const RotoScheduler: FC<RotoSchedulerProps> = ({armChoices, machineName, armId, refresh, readOnly,
                                                 canEdit, canDelete, loadToArmAction}) => {

  const dragContainer = useRef(null)
  
	const [workingTime, setWorkingTime] = useState(DEFAULT_WORKING_TIME);
  const [loading, setLoading] = useState<boolean>(true)
  const [unscheduledOrders, setUnscheduledOrders] = useState<any[]>([])
  const [scheduledOrders, setScheduledOrders] = useState<any[]>([])
  const [hardBreaks, setHardBreaks] = useState<any[]>([])
  const [saved, setSaved] = useState<boolean>(false)
  const [schedulerRefresh, setSchedulerRefresh] = useState<boolean>(false)
  const [refreshTable, setRefreshTable] = useState<boolean>(false)

  const [setupsheetViewOpen, setSetupsheetViewOpen] = useState<boolean>(false)
  const [setupsheetMachineName, setSetupsheetMachineName] = useState<string | undefined>('')
  const [setupsheetItemName, setSetupsheetItemName] = useState<string | undefined>(undefined)

  const [updateMoldCountId, setUpdateMoldCountId] = useState<number | undefined>(undefined)
  const [updateMoldCountQuantity, setUpdateMoldCountQuantity] = useState<number>(1)

  const theme = useTheme()
  const headerRowColor: string = colors[theme.palette.mode].table.header

  // ========================== scheduler unassigned store deps
  const [unassignedStore] = useState(new UnassignedStore())

  /**
   * @param {Event} record Event record
   * Updates state hook when adding item to the unassigned store
   */
  const onUnassignedStoreAdd = useCallback(
    (props: any) => {
      const { records } = props
      const flatData: any[] = records.map((rec: any) => rec.originalData)
      setUnscheduledOrders([...unscheduledOrders, ...flatData])
    },
    [unscheduledOrders]
  )

  /**
   * @param {Event} record Event record
   * Updates state hook when removing item from the unassigned store
   */
  const onUnassignedStoreRemove = useCallback(
    (props: any) => {
      const { records } = props
      const recordsIds = records.map((rec: any) => +rec.data.id)

      const filteredItems = unscheduledOrders.filter(
        (item: any) => !recordsIds.includes(item.id)
      )
      setUnscheduledOrders(filteredItems)
    },
    [unscheduledOrders]
  )

  /**
   * @param {String} action string representing the action that fired onUpdate
   * @param {Object} changes Object with three arrays: added, removed, and modified
   * This function is called when the unassignedStore.revertChanges() fun is called
   */
  const onUnassignedStoreRevert = useCallback(
    (props: any) => {
      const { action, changes } = props
      if (action === 'clearchanges') {
        const toAdd = changes.removed.map((obj: any) => obj.data)
        const idsToRemove = changes.added.map((obj: any) => obj.data.id)
        const newState = [...unscheduledOrders, ...toAdd].filter(
          (item: any) => !idsToRemove.includes(item.id)
        )
        setUnscheduledOrders(newState)
      }
    },
    [unscheduledOrders]
  )

  const doSave = (data: any) => {
    saveRotoSchedule(armId, data)
    setSaved(true)
  }
  // actions
  const closeSetupsheetView = () => {
    setSetupsheetViewOpen(false)
    setSetupsheetItemName(undefined)
    setSetupsheetMachineName(undefined)
  }
  const openSetupsheetView = (itemName: string) => {
    setSetupsheetViewOpen(true)
    setSetupsheetItemName(itemName)
    if (machineName)
      setSetupsheetMachineName(machineName)
  }
  const openUpdateMoldCountModal = (id: number, quantity: number) => {
    setUpdateMoldCountId(id)
    setUpdateMoldCountQuantity(quantity)
  }
  const closeUpdateMoldCountModal = () => {
    setUpdateMoldCountId(undefined)
    setUpdateMoldCountQuantity(1)
  }
  const doUpdateMoldCount = (data: any) => {
    console.log('do update mold count :', data)
    setMoldLoadCount(data, () => {
      closeUpdateMoldCountModal()
      setRefreshTable(!refreshTable)
    })
  }

  useEffect(() => {
    // Bind callbacks for adding, removing, and reverting items from the unassigned store.
    Object.assign(unassignedStore, {
      onAdd: onUnassignedStoreAdd,
      onRemove: onUnassignedStoreRemove,
      onRefresh: onUnassignedStoreRevert,
    })
  }, [
    unassignedStore,
    onUnassignedStoreAdd,
    onUnassignedStoreRemove,
    onUnassignedStoreRevert,
  ])

  useEffectApi(() => {
    setSchedulerRefresh(!schedulerRefresh)

    getSubScheduledToArm(armId, (data: any) => {
      // console.log('data :', data)
      // const data = mockData
      const scheduledOrders: any[] = mapToOrderModel(data.scheduled_orders)
      const counterWeights: any[] = mapToCounterWeightsModel(data.counter_weights)
      const hardBreaks: any[] = mapToHardBreaksModel(data.hard_breaks)

      setUnscheduledOrders(mapToOrderModel(data.sub_scheduled_orders))
      setScheduledOrders(getOrdersWithStartDates([...scheduledOrders, ...counterWeights], armId, workingTime))
      setHardBreaks(hardBreaks)

      // unassignedStore.add(mapToOrderModel(data.sub_scheduled_orders))
      unassignedStore.bindData(mapToOrderModel(data.sub_scheduled_orders))
      unassignedStore.commit()
      setLoading(false)
    })

  }, [refresh, refreshTable, workingTime])
  // ========================== end scheduler unassigned store deps

  return (
    <>
      <BaseContent loading={loading} sx={{m: 0, mt:  2 }}>
        <Scheduler
          readOnly={readOnly || !canEdit}
          canEdit={canEdit}
          orders={scheduledOrders}
          unassignedStore={unassignedStore}
          armId={armId}
          dragContainer={dragContainer}
          hardBreaks={hardBreaks}
          saveCallback={doSave}
          refresh={schedulerRefresh}
          forceRefresh={() => {
            setLoading(true)
            setRefreshTable(!refreshTable)
          }}
          arms={armChoices.map((arm: any) => ({ ...arm, name: arm.title}))}
          loadToArmAction={loadToArmAction}
					workingTime={workingTime}
					setWorkingTime={setWorkingTime}
        />

        {!readOnly
          ?
					<Box sx={{mt: 1}}>
						<SchedulerDragContainer dragContainerRef={dragContainer} armId={armId}>

							<TableContainer component={Paper} sx={{boxShadow: 2, borderRadius: 3}}>
								<Table size='small'>
									<TableHead>
										<TableRow sx={{align: 'center', backgroundColor: headerRowColor}}>
											<TableCell align='center'>Order</TableCell>
											<TableCell align='center'>Item</TableCell>
											<TableCell align='center'>Description</TableCell>
											<TableCell align='center'>Arm</TableCell>
											<TableCell align='center'>Ship Date</TableCell>
											<TableCell align='center'>Balance</TableCell>
											<TableCell align='center'>Loaded</TableCell>
											<TableCell align='center'>Mold Count</TableCell>
											<TableCell align='center'>Mold Volume</TableCell>
											<TableCell align='center'>Oven Temperature</TableCell>
											<TableCell align='center'>Oven Time</TableCell>
											<TableCell align='center'>Takt Time</TableCell>
											<TableCell> </TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{unscheduledOrders.map((unscheduledOrder: any, key: number) => (
											<ArmScheduleRow armChoices={armChoices} armScheduledOrder={unscheduledOrder} key={key}
																			refreshTable={() => setRefreshTable(!refreshTable)} canEdit={canEdit}
																			canDelete={canDelete} openSetupsheetView={openSetupsheetView}
																			doUpdateMoldCount={openUpdateMoldCountModal} />
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</SchedulerDragContainer>
					</Box>
          : <></>
        }
      </BaseContent>

      <UpdateMoldCountModal orderId={updateMoldCountId} quantity={updateMoldCountQuantity} onClose={closeUpdateMoldCountModal}
                            onSubmit={doUpdateMoldCount} />
      <SetupsheetViewHelper open={setupsheetViewOpen} doClose={closeSetupsheetView} itemName={setupsheetItemName}
                            machineName={setupsheetMachineName} machineSelectable />
    </>
  )
}

export default RotoScheduler
