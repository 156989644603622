import React, { FC, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';

interface SearchBarProps {
	defaultText?: string;
	outlined: boolean;
	width: number;
	onSearch: (q: string) => void;
	q: string;
}

const SearchBar: FC<SearchBarProps> = ({
	defaultText,
	outlined,
	width,
	onSearch,
	q
}) => {
	const [value, setValue] = useState<string>(q);
	const [text, setText] = useState<string>('Search...');
	const theme = useTheme();

	useEffect(() => {
		if (defaultText) {
			setText(defaultText);
		}
	}, [defaultText]);

	const handleQ = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value);
	const handleSearchSubmit = () => onSearch(value);
	const handleOnEnter = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			handleSearchSubmit();
		}
	};
	const handleClearSearch = () => {
		setValue('');
		onSearch('');
	};

	return (
		<Box sx={{
			width: `${width}%`,
			maxWidth: '250px',
			minWidth: '150px',
		}}>
			<TextField
				placeholder={text}
				variant={outlined ? "outlined" : "standard"}
				size="small"
				value={value}
				onChange={handleQ}
				onKeyPress={handleOnEnter}
				sx={{
					borderRadius: '20px',
					'& .MuiOutlinedInput-root': {
						borderRadius: '20px',
						height: 40,
						boxSizing: 'border-box',
						...(outlined && {
							'& fieldset': {
								borderColor: theme.palette.primary.main,
							},
							'&:hover fieldset': {
								borderColor: theme.palette.primary.main,
							},
							'&.Mui-focused fieldset': {
								borderColor: theme.palette.primary.main,
							},
						})
					},
					width: '100%',
				}}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end" sx={{ pr: 1 }}>
							<IconButton
								sx={{
									p: 0.5,
									backgroundColor: theme.palette.primary.light,
									'&:hover': {
										backgroundColor: theme.palette.primary.main,
									},
									color: theme.palette.primary.contrastText,
									transition: 'background-color 0.2s',
								}}
								onClick={value ? handleClearSearch : handleSearchSubmit}
								edge="end"
							>
								{value ? <ClearIcon fontSize="small" /> : <SearchIcon fontSize="small" />}
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
		</Box>
	);
};

export default SearchBar;