import React, { FC, useState } from 'react'
import { useTheme } from '@mui/material/styles'
// mui icons
import Refresh from '@mui/icons-material/Refresh'
// mui imports
import TableContainer from '@mui/material/TableContainer'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
// custom imports
import {useEffectApi} from '../../globals'
import {getLineplanLiveData} from './LineplanLiveTableApi'
import BaseContent from '../BaseContent'
import Legend from '../Legend'
import {colors} from '../../colors'
import BaseTooltip from '../BaseTooltip'
import DropdownActions, {DropdownAction} from '../DropdownActions'
import '../../table/rowHighlight.css'

const LINE: number = 2

interface LineplanLiveTableProps {
  id: number
  refresh?: boolean | undefined
  refreshable?: boolean | undefined
  canLog?: boolean | undefined
  viewSetupsheet?: ((item_name: string) => void) | undefined
  logProduction?: ((id: number | undefined, logType: number) => void) | undefined
  pullBom?: ((id: number | undefined, qty?: number) => void) | undefined
  channel?: boolean
  logs24hr?: boolean
  actions?: any[] | undefined
  doLoad?: boolean | undefined
}

/**
 * lineplan live table
 * @param id lineplan id
 * @param refresh refresh table flag (for when a child of an accordion)
 * @param refreshable if true, displays a refresh button (for when not a child of an accordion)
 * @param canLog grants logging permission
 * @param logProduction action to open log production modal
 * @param pullBom action to open pull bom modal
 * @param actions
 * @param doLoad
 */
const LineplanLiveTable: FC<LineplanLiveTableProps> = ({id, refresh, refreshable,
                                                         canLog, logProduction, pullBom, viewSetupsheet,
                                                         actions, doLoad}) => {
  const theme: any = useTheme()

  const [thisRefresh, setThisRefresh] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<any[]>([])

  const doRefresh = () => {
    setLoading(true)
    setThisRefresh(!refresh)
  }

  useEffectApi(() => {
    if (doLoad) {
      // console.log(`line plan live table use effect id=${id} :`, loading)
      getLineplanLiveData(id, (data: any[]) => {
        setData(data)
				// console.log(data)
        setLoading(false)
      })
    }
  }, [id, refresh, thisRefresh, loading])

  // helpers
  const calcPerGroup = (takt: number, quantity: number) => {
    return (takt * quantity) % 1 === 0 ? takt * quantity : (takt * quantity).toFixed(1)
  }
  const generateActions = () => {
    let theseActions: any = []

    if (refreshable)
      theseActions.push({text: 'Refresh', action: doRefresh, icon: <Refresh />})

    if (actions)
      theseActions = theseActions.concat(actions)

    return theseActions
  }

	const itemCell = (object: any) => {
    if (viewSetupsheet && object.has_process_instructions)
      return (
        <TableCell align='center'>
          <BaseTooltip text='View Setupsheet'>
            <Button onClick={() => viewSetupsheet ? viewSetupsheet(object.item) : ''} sx={{maxHeight: 20}}>
              {object.item}
            </Button>
          </BaseTooltip>
        </TableCell>
      )
    else return <TableCell align='center'>{object.item}</TableCell>
  }

  // conditional clickable order cell for production logging
  const orderCell = (object: any) => {
    if (logProduction)
      return (
        <TableCell align='center'>
          <BaseTooltip text='Log Production'>
            <Button onClick={() => logProduction ? logProduction(parseInt(object.order_id), LINE) : {}} sx={{maxHeight: 20}}>
              {object.order_number}
            </Button>
          </BaseTooltip>
        </TableCell>
      )
    else return <TableCell align='center'>{object.order_number}</TableCell>
  }

  // conditional clickable order cell for pull bom
  const priorityCell = (object: any) => {

    if (pullBom !== null && pullBom !== undefined)
      return (
        <TableCell align='center'>
					<Button sx={{ maxHeight: 20 }} onClick={() => pullBom(object.order_id, object.live_quantity)}>{object.live_number}</Button>
        </TableCell>
      )
    else return <TableCell align='center'>{object.live_number}</TableCell>

  }

  const getRowColor = (color: string) => theme.palette.color[color]

  return (
    <BaseContent sx={{ m: 0 }} loading={loading}>
      <Grid sx={{mb: 1, display: 'flex', justifyContent: 'space-between'}}>
				<Grid item xs={12}>
					{generateActions().length > 0 ?
						<DropdownActions name='Actions' id={id} noMin>
							{generateActions().map((action: any, key: number) => (
								<div key={key}>
									<DropdownAction id={id} name='' text={action.text} action={action.action} icon={action.icon} />
								</div>
							))}
						</DropdownActions>
						:
						<></>}
				</Grid>
        <Grid xs={3} sx={{ my: 'auto'}}>
          <Legend legendItems={[
            {color: getRowColor('green'), text: 'Completed', tooltipText: 'Complete'},
            {color: getRowColor('yellow'), text: 'In Progress', tooltipText: 'Started but not complete'},
          ]} />
        </Grid>
      </Grid>
      <TableContainer component={Paper} sx={{ borderRadius: 3 }}>
        <Table size='small'>
          <TableHead sx={{backgroundColor: colors[theme.palette.mode].table.header}}>
            <TableRow>
              <TableCell align='center'>Priority</TableCell>
              <TableCell align='center'>Order</TableCell>
              <TableCell align='center'>Item</TableCell>
              <TableCell align='center'>Description</TableCell>
              <TableCell align='center'>Balance</TableCell>
              <TableCell align='center'>Available</TableCell>
              <TableCell align='center'>Quantity</TableCell>
              <TableCell align='center'>Completed</TableCell>
              <TableCell align='center'>Takt Time</TableCell>
              <TableCell align='center'>Per Group</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ?
              <>
                {data.map((object: any, key: number) => (
                  <TableRow className={theme.palette.mode === 'dark' ? 'dark' : 'light'} key={key}
                            sx={{backgroundColor: getRowColor(object.live_color)}}>
                    {canLog ? priorityCell(object) : <TableCell align='center'>{object.live_number} </TableCell>}
                    {object.available > 0 && canLog ? orderCell(object) : <TableCell align='center'>{object.order_number}</TableCell>}
                    {viewSetupsheet ? itemCell(object) : <TableCell align='center'>{object.item}</TableCell>}
                    <TableCell align='center'>{object.description}</TableCell>
                    <TableCell align='center'>{object.balance}</TableCell>
                    <TableCell align='center'>{object.available}</TableCell>
                    <TableCell align='center'>{object.live_quantity}</TableCell>
                    <TableCell align='center'>{object.completed}</TableCell>
                    <TableCell align='center'>{object.takt}</TableCell>
                    <TableCell align='center'>{calcPerGroup(object.takt, object.live_quantity)}</TableCell>
                  </TableRow>
                ))}
              </>
              :
              <TableRow>
                <TableCell colSpan={9} sx={{textAlign: 'center'}}>No data</TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </BaseContent>
  )
}

export default LineplanLiveTable