import React, {FC, useMemo, useState} from "react";
// mui imports
import Typography from "@mui/material/Typography";
// custom imports
import ProgressBar from "../../../../utils/globalComps/ProductivityProgress/ProgressBar";
import { ArmLogBar, LineplanLoggedProductivity } from "./hooks/Interfaces";
import ProgressTickedBar from "../../../../utils/globalComps/ProductivityProgress/ProgressTickedBar";
import { Grid } from "@mui/material";


interface MachineBarProps {
  bar: ArmLogBar
}
const MachineBar: FC<MachineBarProps> = ({bar}) => {
    return (
			<Grid sx={{paddingBottom: 1}}>
				<Grid sx={{display: 'flex', justifyContent: 'space-between', paddingX: 2}}>
					<Grid sx={{display: 'inherit', alignItems: 'baseline'}}>
						<Typography variant="h6" fontWeight={'bold'} paddingRight={1}>{bar.shift} Shift:</Typography>
						<Typography variant='h6' fontWeight={'regular'}> Logged {bar.logged} / Expected {bar.expected}</Typography>
					</Grid>
					<Grid sx={{display: 'fleinheritx', alignItems: 'baseline'}}>
						<Typography variant='h6' fontWeight={'regular'}> Productivity: {bar.avg_productivity}</Typography>
					</Grid>
				</Grid>
				<Grid 
					sx={{
						paddingTop: 2,
						paddingBottom: 1,
						paddingRight: bar.progress == 100 ? 2 : 0,
						display: 'flex', 
						justifyContent: 'center'
					}}
				>
					<ProgressTickedBar progress={bar.progress} ticks={bar.ticks} flatEnd={bar.progress == 100 ? true : false}><></></ProgressTickedBar>
				</Grid>
				<Grid sx={{ display: 'flex', alignItems: 'baseline', paddingX: 2 }}>
						<Typography fontWeight={'bold'} paddingRight={1}>Logged in:</Typography>
						{bar.log_ins.map((login: { name: string, count: number }, key: number) => (
							<React.Fragment key={key}>
								<Typography paddingRight={1}>{login.name}</Typography>
								{key !== bar.log_ins.length - 1 && <Typography paddingRight={1}>|</Typography>}
							</React.Fragment>
						))}
				</Grid>
			</Grid>
      
    )
}

interface MachineProgressBarsProps {
  armLogData: ArmLogBar[]
}
export const ArmLogBars: FC<MachineProgressBarsProps> = ({armLogData}) => {

	const barData = useMemo(() => armLogData ?? [], [armLogData])

	return (
		<Grid>
			{barData.map((bar: ArmLogBar, key: number) => (
				<Grid 
					sx={{
						marginBottom: 2
					}} 
					key={key}
				>
					<MachineBar bar={bar} ></MachineBar>
				</Grid>
			))}
		</Grid>
	)
}

interface LineplanBarProps {
	bar: LineplanLoggedProductivity
}
const LineplanBar: FC<LineplanBarProps> = ({ bar }) => {
	return (
		<Grid sx={{ paddingBottom: 1 }}>
			<Grid sx={{ display: 'flex', justifyContent: 'space-between', paddingX: 2 }}>
				<Grid sx={{ display: 'inherit', alignItems: 'baseline' }}>
					<Typography variant="h6" fontWeight={'bold'} paddingRight={1}>{bar.shift} Shift:</Typography>
					<Typography variant='h6' fontWeight={'regular'}> Takt Logged {bar.logged} / Expected {bar.target}</Typography>
				</Grid>
				<Grid sx={{ display: 'fleinheritx', alignItems: 'baseline' }}>
					<Typography variant='h6' fontWeight={'regular'}> Productivity: {bar.logged_progress}%</Typography>
				</Grid>
			</Grid>
			<Grid
				sx={{
					paddingTop: 2,
					paddingBottom: 1,
					paddingRight: bar.logged_progress == 100 ? 2 : 0,
					display: 'flex',
					justifyContent: 'center'
				}}
			>
				<ProgressTickedBar progress={bar.logged_progress} ticks={bar.ticks} flatEnd={bar.logged_progress == 100 ? true : false}><></></ProgressTickedBar>
			</Grid>
			<Grid sx={{ display: 'flex', alignItems: 'baseline', paddingX: 2 }}>
				<Typography fontWeight={'bold'} paddingRight={1}>Logged in:</Typography>
				{bar.logged_ins.map((login: { name: string, count: number }, key: number) => (
					<React.Fragment key={key}>
						<Typography paddingRight={1}>{login.name}</Typography>
						{key !== bar.logged_ins.length - 1 && <Typography paddingRight={1}>|</Typography>}
					</React.Fragment>
				))}
			</Grid>
		</Grid>

	)
}

interface LineplanProgressBarsProps {
  loggedProductivity: LineplanLoggedProductivity[]
}
export const LoggedProductivityBars: FC<LineplanProgressBarsProps> = ({ loggedProductivity }) => {

	const barData = useMemo(() => loggedProductivity ?? [], [loggedProductivity])

	return (
		<Grid>
			{barData.map((bar: LineplanLoggedProductivity, key: number) => (
				<Grid 
					sx={{
						marginBottom: 2
					}} 
					key={key}
				>
					<LineplanBar bar={bar} ></LineplanBar>
				</Grid>
			))}
		</Grid>
	)
}



