import { useEffect, useMemo, useState } from "react";
import { LineplanProcessedOrder, MachineProcessedOrder, Statistics } from './Interfaces';
import useWebsocket from "../../../../../websocket/hooks/useWebsocket";
import { format } from 'date-fns';

const useStatistics = () => {
	const [data, setData] = useState<Statistics>({ 
		page_state: { 
			date: null,
			view: 'molding'
		}, 
		machine_statistics: {}, 
		post_molding_statistics: {}, 
		assembly_statistics: {}
	});
	const { loading, sendMessage, isVisible, setPageState } = useWebsocket('/processing/production_statistics/', setData);

	let pageState = data.page_state;
	const machineStats = useMemo(() => data.machine_statistics, [data]);
	const postMoldingStats = useMemo(() => data.post_molding_statistics, [data]);
	const assemblyStats = useMemo(() => data.assembly_statistics, [data]);

	useEffect(() => {
		if (!isVisible) {
			setPageState(pageState);
		}
	}, [isVisible]);

	const combineProcessedOrders = (statistics: any) => {
		let processedOrders: { [key: string]: MachineProcessedOrder | LineplanProcessedOrder } = {};

		Object.keys(statistics).forEach(machine => {
			if (!statistics[machine].processed_orders) return;
			const orders = statistics[machine].processed_orders;

			orders.forEach((order: MachineProcessedOrder | LineplanProcessedOrder) => {
				const orderNum = order.order;

				if (orderNum in processedOrders) {
					// Update existing order
					processedOrders[orderNum].conforming_parts += order.conforming_parts;
					processedOrders[orderNum].non_conforming_parts += order.non_conforming_parts;

					// Type guard to check if both the existing and new order have skipped_count
					if ('skipped_count' in order && 'skipped_count' in processedOrders[orderNum]) {
						(processedOrders[orderNum] as MachineProcessedOrder).skipped_count += (order as MachineProcessedOrder).skipped_count;
					}
				} else {
					// Create new order, preserving the original type
					if ('skipped_count' in order) {
						// It's a MachineProcessedOrder
						processedOrders[orderNum] = {
							...order
						} as MachineProcessedOrder;
					} else {
						// It's a LineplanProcessedOrder
						processedOrders[orderNum] = {
							...order
						} as LineplanProcessedOrder;
					}
				}
			});
		});

		return processedOrders;
	};

	return {
		loading, sendMessage,
		pageState,
		machineStats, postMoldingStats, assemblyStats, 
		combineProcessedOrders
	};
};

export default useStatistics;
