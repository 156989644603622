import { FC, useMemo } from "react"
import { Table, Column } from '../../../../../websocket/tables/Table'
import { 
	LineplanProcessedOrder,
	MachineProcessedOrder,
	OverallLineplanStatistics, OverallMachineStatistics  
} from "../hooks/Interfaces"

interface OverallStatisticsProps {
	data: OverallMachineStatistics | OverallLineplanStatistics | {}
	view: 'molding' | 'post_molding' | 'assembly'
}

const OverallStatistics: FC<OverallStatisticsProps> = ({data, view}) => {

	const columns: { [key: string]: Column[] } = {
		
		molding: [
			{
				header: 'Conforming', headerAlign: 'center',
				value: 'conforming', valueAlign: 'center'
			},
			{
				header: 'Non Conforming', headerAlign: 'center',
				value: 'non_conforming', valueAlign: 'center'
			},
			{
				header: 'Non Conforming %', headerAlign: 'center',
				value: 'non_conforming_percent', valueAlign: 'center'
			},
			{
				header: 'Logged Arms', headerAlign: 'center',
				value: 'logged_arms', valueAlign: 'center'
			},
			{
				header: 'Skipped Arms', headerAlign: 'center',
				value: 'skipped_arms', valueAlign: 'center'
			},
			{
				header: 'Molds Loaded', headerAlign: 'center',
				value: 'molds_loaded', valueAlign: 'center'
			},
			{
				header: 'Molds Unloaded', headerAlign: 'center',
				value: 'molds_unloaded', valueAlign: 'center'
			},
		],

		post_molding: [
			{
				header: 'Conforming', headerAlign: 'center',
				value: 'conforming', valueAlign: 'center'
			},
			{
				header: 'Non Conforming', headerAlign: 'center',
				value: 'non_conforming', valueAlign: 'center'
			},
			{
				header: 'Non Conforming %', headerAlign: 'center',
				value: 'non_conforming_percent', valueAlign: 'center'
			}
		],

		assembly: [
			{
				header: 'Conforming', headerAlign: 'center',
				value: 'conforming', valueAlign: 'center'
			},
			{
				header: 'Non Conforming', headerAlign: 'center',
				value: 'non_conforming', valueAlign: 'center'
			},
			{
				header: 'Non Conforming %', headerAlign: 'center',
				value: 'non_conforming_percent', valueAlign: 'center'
			},
		]
	}

	const isEmpty = (obj: {} ) => obj == undefined || Object.keys(obj).length === 0;
	const rows = isEmpty(data) ? [] : [data]

	return (
		<Table
			sx={{ borderRadius: 4 }}
			columns={columns[view]}
			rows={rows}
		/>
	)
}

export default OverallStatistics