import React, { FC } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

interface ActionType {
	text: string;
	icon?: React.ReactNode;
	action: () => void;
	right?: boolean;
	outlined?: boolean;
	tooltip?: string;
	loadingButton?: boolean;
	loading?: boolean;
}

interface TableActionsProps {
	actions: ActionType[];
	marginBottom?: boolean;
}

const TableActions: FC<TableActionsProps> = ({ actions, marginBottom }) => {
	const leftActions = actions.filter((act) => act.right === undefined);
	const rightActions = actions.filter((act) => act.right);

	const renderButton = (act: ActionType, key: number) => {
		const commonProps = {
			onClick: act.action,
			variant: act.outlined ? 'outlined' : 'contained',
			startIcon: act.icon,
			sx: {
				borderRadius: 3,
				mr: 1,
				whiteSpace: 'nowrap'
			}
		} as const;

		if (act.loadingButton) {
			return (
				<LoadingButton
					{...commonProps}
					loading={act.loading}
					key={key}
				>
					{act.text}
				</LoadingButton>
			);
		}

		return (
			<Button {...commonProps} key={key}>
				{act.text}
			</Button>
		);
	};

	return (
		<Grid
			container
			sx={{
				width: 'fit-content',
				minHeight: '40px',
			}}
		>
			<Grid
				item
				xs={6}
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: 1, 
					pr: 1
				}}
			>
				{leftActions.map((act, key) => (
					<Box key={key} sx={{ minWidth: 'fit-content' }}>
						{renderButton(act, key)}
					</Box>
				))}
			</Grid>
			<Grid
				item
				xs={6}
				sx={{
					display: 'flex',
					justifyContent: 'flex-end',
					alignItems: 'center',
					gap: 1
				}}
			>
				{rightActions.map((act, key) => (
					<Box key={key} sx={{ minWidth: 'fit-content' }}>
						{renderButton(act, key)}
					</Box>
				))}
			</Grid>
		</Grid>
	);
};

export default TableActions;