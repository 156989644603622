import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InfoIcon from '@mui/icons-material/Info';
import Divider from '@mui/material/Divider';
import NotificationsIcon from '@mui/icons-material/Notifications';
import useNotificationPreferences, { Preferences } from './useNotificationPreferences';
import { Grid, Tooltip } from '@mui/material';

const NotificationsButton: React.FC = () => {
	const [open, setOpen] = useState(false);
	const { preferences, sendMessage } = useNotificationPreferences();

	// Local state to manage form changes
	const [localPreferences, setLocalPreferences] = useState<Preferences>({
		enable_email_notifications: false,
		enable_sms_notifications: false,
		enable_inbound_sync_notifications: false,
		enable_outbound_sync_notifications: false,
		enable_item_scrap_notifications: false,
		consecutive_scrap: 2,
		enable_order_scrap_rate_notifications: false,
		scrap_threshold: 5.0,
		scrap_rate_sensitivity: 2,
		enabled_logging_interval_notifications: false,
		enable_skipped_arm_notifications: false,
	});

	// State to track validation errors
	const [errors, setErrors] = useState<Record<string, string>>({});

	// Initialize local preferences when preferences from the hook are available
	useEffect(() => {
		setLocalPreferences(preferences);
	}, [preferences]);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = () => {
		// Validate preferences before saving
		const isValid = validatePreferences();

		if (isValid) {
			// Use sendMessage to save the preferences
			sendMessage('save_preferences', { preferences: localPreferences });
			console.log('Preferences saved:', localPreferences);
			setOpen(false);
		} else {
			console.log('Validation failed. Please fill out all fields correctly.');
		}
	};

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = event.target;
		setLocalPreferences((prevPreferences) => ({
			...prevPreferences,
			[name]: checked,
		}));
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setLocalPreferences((prevPreferences) => ({
			...prevPreferences,
			[name]: value, // Allow the value to be a string for now
		}));
	};

	// Function to validate the preferences before saving
	const validatePreferences = (): boolean => {
		let isValid = true;
		const numericFields: (keyof Preferences)[] = ['consecutive_scrap', 'scrap_threshold', 'scrap_rate_sensitivity'];

		// Create a new error object to store validation errors
		const newErrors: Record<string, string> = {};

		// Check if all numeric fields are filled and valid numbers
		numericFields.forEach((field) => {
			const value = parseFloat(localPreferences[field] as unknown as string);

			if (isNaN(value) || value < 0) {
				isValid = false;
				newErrors[field] = `Please enter a valid ${field.replace(/_/g, ' ')}`;
				console.log(`Invalid value for ${field}: ${localPreferences[field]}`);
			} else {
				// Update the local preferences with the parsed number
				setLocalPreferences((prevPreferences) => ({
					...prevPreferences,
					[field]: value
				}));
			}
		});

		// Update the error state
		setErrors(newErrors);

		return isValid;
	};

	return (
		<div>
			<Button
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
				startIcon={<NotificationsIcon />}
				sx={{ borderRadius: 3 }}
			>
				Notifications
			</Button>
			<Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" PaperProps={{ sx: { borderRadius: 5, textWrap: 'nowrap' } }}>
				<form
					onSubmit={(e) => {
						e.preventDefault(); // Prevent default form submission
						handleSave(); // Call handleSave on form submit
					}}
				>
					<DialogTitle>Notification Preferences</DialogTitle>
					<DialogContent dividers sx={{ p: 0, maxHeight: '70vh', overflowY: 'auto' }}>
						<Grid sx={{ px: 3, pt: 2, pb: 1 }}>
							<DialogContentText>
								Notification Types:
							</DialogContentText>
						</Grid>

						<Grid sx={{ px: 3, display: 'flex', flexDirection: 'column' }}>
							{/* Email Notifications */}
							<Grid container alignItems="center">
								<FormControlLabel
									control={
										<Checkbox
											checked={localPreferences.enable_email_notifications}
											name="enable_email_notifications"
											onChange={handleCheckboxChange}
										/>
									}
									label="Enable Email Notifications"
								/>
							</Grid>

							{/* SMS Notifications */}
							{/* <Grid container alignItems="center">
								<FormControlLabel
									control={
										<Checkbox
											checked={localPreferences.enable_sms_notifications}
											name="enable_sms_notifications"
											onChange={handleCheckboxChange}
										/>
									}
									label="Enable SMS Notifications"
								/>
							</Grid> */}
						</Grid>

						<Divider sx={{ my: 2 }} />

						<Grid sx={{ px: 3, display: 'flex', flexDirection: 'column' }}>
							<DialogContentText pt={0}>
								Notification customization:
							</DialogContentText>

							{/* Inbound Sync Notifications */}
							{/* <Grid container alignItems="center">
								<FormControlLabel
									control={
										<Checkbox
											checked={localPreferences.enable_inbound_sync_notifications}
											name="enable_inbound_sync_notifications"
											onChange={handleCheckboxChange}
										/>
									}
									label="Enable Inbound Sync Alerts"
								/>
								<Tooltip title="Get alerts when inbound syncs fail" arrow>
									<InfoIcon sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</Grid> */}

							{/* Outbound Sync Notifications */}
							<Grid container alignItems="center">
								<FormControlLabel
									control={
										<Checkbox
											checked={localPreferences.enable_outbound_sync_notifications}
											name="enable_outbound_sync_notifications"
											onChange={handleCheckboxChange}
										/>
									}
									label="Enable Outbound Sync Alerts"
								/>
								<Tooltip title="Get alerts when outbound syncs fail" arrow>
									<InfoIcon sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</Grid>

							<Divider sx={{ my: 2 }} />

							{/* Item Specific Scrap Notifications */}
							<Grid container alignItems="center">
								<FormControlLabel
									control={
										<Checkbox
											checked={localPreferences.enable_item_scrap_notifications}
											name="enable_item_scrap_notifications"
											onChange={handleCheckboxChange}
										/>
									}
									label="Enable Item Scrap Alerts"
								/>
								<Tooltip title="Get alerts when items are repeatedly logged as scrap. 'Consecutive Scrap' defines the threshold for when you should be notified that an Item has multiple non conforming parts in a row." arrow>
									<InfoIcon sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</Grid>

							<TextField
								label="Consecutive Scrap"
								type="number"
								name="consecutive_scrap"
								value={localPreferences.consecutive_scrap}
								onChange={handleInputChange}
								fullWidth
								error={!!errors.consecutive_scrap}
								helperText={errors.consecutive_scrap}
								sx={{ my: 1 }}
								inputProps={{ min: 0 }} // Ensure no negative values
							/>
						</Grid>

						<Divider sx={{ my: 2 }} />

						<Grid sx={{ px: 3, display: 'flex', flexDirection: 'column' }}>
							{/* Order Scrap Rate Notifications */}
							<Grid container alignItems="center">
								<FormControlLabel
									control={
										<Checkbox
											checked={localPreferences.enable_order_scrap_rate_notifications}
											name="enable_order_scrap_rate_notifications"
											onChange={handleCheckboxChange}
										/>
									}
									label="Enable Order Scrap Rate Alerts"
								/>
								<Tooltip
									title={
										<>
											As orders are processed, you will be notified if the scrap rate goes above the 'Scrap Threshold' (Scrap Rate %).
											{/* 'Scrap Rate Sensitivity' is a value used to fine-tune how sensitive this notification will be for orders that have just begun production. Values 1-5 (1 being the least sensitive and 5 being the most) can be set. */}
										</>
									}
									arrow
								>
									<InfoIcon sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</Grid>

							<TextField
								label="Scrap Threshold (%)"
								type="number"
								name="scrap_threshold"
								value={localPreferences.scrap_threshold}
								onChange={handleInputChange}
								fullWidth
								error={!!errors.scrap_threshold}
								helperText={errors.scrap_threshold}
								sx={{ mt: 1, mb: 2 }}
								inputProps={{ step: 0.1, min: 0, max: 100 }} // Ensure sensible range
							/>

							{/* <TextField
                                label="Scrap Rate Sensitivity"
                                type="number"
                                name="scrap_rate_sensitivity"
                                value={localPreferences.scrap_rate_sensitivity}
                                onChange={handleInputChange}
                                fullWidth
                                error={!!errors.scrap_rate_sensitivity}
                                helperText={errors.scrap_rate_sensitivity}
                                sx={{ mt: 1, mb: 2 }}
                                inputProps={{ step: 1, min: 1, max: 5 }} // Set min and max range
                            /> */}
						</Grid>

						<Divider sx={{ my: 2 }} />

						<Grid sx={{ px: 3, display: 'flex', flexDirection: 'column' }}>
							{/* Logging Interval Notifications */}
							<Grid container alignItems="center">
								<FormControlLabel
									control={
										<Checkbox
											checked={localPreferences.enabled_logging_interval_notifications}
											name="enabled_logging_interval_notifications"
											onChange={handleCheckboxChange}
										/>
									}
									label="Enable Logging Interval Alerts"
								/>
								<Tooltip title="Get alerts when nothing has been logged in double the logging interval time" arrow>
									<InfoIcon sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</Grid>
						</Grid>

						<Divider sx={{ my: 2 }} />

						<Grid sx={{ pb: 2, px: 3, display: 'flex', flexDirection: 'column' }}>
							{/* Skipped Arm Notifications */}
							<Grid container alignItems="center">
								<FormControlLabel
									control={
										<Checkbox
											checked={localPreferences.enable_skipped_arm_notifications}
											name="enable_skipped_arm_notifications"
											onChange={handleCheckboxChange}
										/>
									}
									label="Enable Skipped Arm Alerts"
								/>
								<Tooltip title="Get alerts when arms are skipped more than once." arrow>
									<InfoIcon sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="error">
							Cancel
						</Button>
						<Button type="submit" color="primary">
							Save
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</div>
	);
};

export default NotificationsButton;
