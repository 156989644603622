import React, { FC, useState } from 'react'
// mui imports
import { useTheme } from '@mui/material/styles'
// mui icon imports
import ArrowCircleUp from '@mui/icons-material/ArrowCircleUp'
import ArrowCircleDown from '@mui/icons-material/ArrowCircleDown'
import ArrowBack from '@mui/icons-material/ArrowBack'
import LibraryBooks from '@mui/icons-material/LibraryBooks'
import Print from '@mui/icons-material/Print'
// custom imports
import { colors } from '../../../utils/colors'
import { loadToArm } from '../scheduling/MachineScheduling/MachineSchedulingApi'
import { getArmLoadingData, getArms, getMachine, getPowderLabelData, loadAfter, removeLoadQueue, unloadAllMolds,
  unloadAllMoldsFromArm, unloadMold } from './ArmLoadingApi'
import { doConfirm } from '../../../utils/modals/Confirm'
import { POWDER, printLabel } from '../../../utils/labelPrinting/labelPrinting'
import { MOLDING } from '../../../utils/globalComps/ProductionLog/ProductionLogHelpers'
import { useEffectApi } from '../../../utils/globals'
import PageTitle from '../../../utils/globalComps/PageTitle'
import BaseContent from '../../../utils/globalComps/BaseContent'
import MachineLoadDisplay from '../../../utils/globalComps/MachineLoadDisplay/MachineLoadDisplay'
import ApiTable from '../../../utils/table/ApiTable'
import LoadAfterModal from './LoadAfterModal'
import RemoveOrderConfirmModal from './RemoveOrderConfirmModal'
import SetupsheetViewHelper from '../management/setupsheets/SetupsheetViewHelper'
import LoadToArmModal from './LoadToArmModal'
import ProcessingUserActions from '../../../utils/globalComps/ProcessingUserActions/ProcessingUserActions'
import ProductionLogModal from '../../../utils/globalComps/ProductionLog/ProductionLogModal'

interface LoadingProps {
  id: number
}
/**
 * ArmLoading component
 * @param id machine id
 */
const ArmLoading: FC<LoadingProps> = ({id}) => {
  const theme = useTheme()

  const [machine, setMachine] = useState<any>({})
  const [arms, setArms] = useState<any[]>([])
  const [machineDisplayLoading, setMachineDisplayLoading] = useState<boolean>(true)
  const [refreshMachineDisplay, setRefreshMachineDisplay] = useState<boolean>(false)
  const [suppressLoadDisplay, setSuppressLoadDisplay] = useState<boolean>(false)
  const [advancedScheduling, setAdvancedScheduling] = useState<boolean>(false)

  const [productivityEnabled, setProductivityEnabled] = useState<boolean>(false)
  const [canViewProductivity, setCanViewProductivity] = useState<boolean>(false)

  const [permissions, setPermissions] = useState<any>(undefined)

  const [loadAfterModalOpen, setLoadAfterModalOpen] = useState<boolean>(false)
  const [loadAfterItems, setLoadAfterItems] = useState<any[]>([])

  const [loadToArmModalId, setLoadToArmModalId] = useState<number | undefined>(undefined)
  const [loadToArmModalName, setLoadToArmModalName] = useState<string | undefined>(undefined)
  const [loadToArmId, setLoadToArmId] = useState<number | undefined>(undefined)
  const [loadToArmLoadingMore, setLoadToArmLoadingMore] = useState<boolean>(false)

  const [removeOrderModalOpen, setRemoveOrderModalOpen] = useState<boolean>(false)
  const [removeOrderModalOrder, setRemoveOrderModalOrder] = useState<string>('')
  const [removeOrderModalArm, setRemoveOrderModalArm] = useState<string>('')
  const [removeOrderModalOrderId, setRemoveOrderModalOrderId] = useState<number | undefined>(undefined)

  const [setupsheetItemName, setSetupsheetItemName] = useState<string>('')
  const [setupsheetMachineName, setSetupsheetMachineName] = useState<string>('')
  const [setupsheetViewOpen, setSetupsheetViewOpen] = useState<boolean>(false)

  const [canViewProduction, setCanViewProduction] = useState<boolean>(false)
  const [productionLogsOrderId, setProductionLogsOrderId] = useState<number | undefined>(undefined)
  const [productionLogsOpen, setProductionLogsOpen] = useState<boolean>(false)

  useEffectApi(() => {

    document.title = 'Loading | RotoEdgePro'

    getMachine(id, (machine: any) => {
      document.title = `Loading | ${machine.name} | RotoEdgePro`
      setMachine(machine)
    })
    getArms(id, (arms: any) => {
      setArms(arms)
      setMachineDisplayLoading(false)
    })
    getArmLoadingData((data: any) => {
      setAdvancedScheduling(data.advancedScheduling)
      setProductivityEnabled(data.productivityEnabled)

      setCanViewProductivity(data.canViewProductivity)
      setCanViewProduction(data.canViewProduction)
    })

    setMachine({...machine, refresh: !machine.refresh})

	}, [id, machineDisplayLoading]) // setMachine, setArms, 

  // helpers
  const refreshTable = () => {
    // refresh the machine table
    setSuppressLoadDisplay(true)
    let refreshMachine = machine
    refreshMachine['refresh'] = !refreshMachine.refresh
    setMachine(refreshMachine)
  }
  const getMachineNameDisplay = () => machine.name ? `/ ${machine.name}` : ''
  const getRowColor = (color: string) => colors[theme.palette.mode].table.row[color]
  const getLegend = () => {
    if (advancedScheduling) {
      return [
        {color: getRowColor('green'), text: 'Loaded', tooltipText: 'Item is loaded to an Arm.'},
        {color: getRowColor('yellow'), text: 'In Queue', tooltipText: 'Item is scheduled to an Arm.'},
      ]
    } else {
      return [
        {color: getRowColor('green'), text: 'Loaded', tooltipText: 'Item is loaded to an Arm on the Machine.'},
        {color: getRowColor('yellow'), text: 'In Load Queue', tooltipText: 'Item is in an Arm Load Queue.'},
      ]
    }
  }
  const getRowActions = () => {


    let actions: any[] = []

    if (permissions?.edit)
      if (advancedScheduling)
        actions = [
          {icon: <ArrowCircleUp />, action: openLoadToArm, actionType: 'object', text: 'Load to Arm', condition: 'loadable', conditionValue: true},
          {icon: <ArrowCircleDown />, action: (orderId: number | undefined) => doUnloadAllMolds(machine.name, orderId), text: 'Unload all molds', tooltip: 'Unloads all instances of this mold on this machine', condition: 'loaded', conditionValue: true},
					{ icon: <Print />, actionType: 'object', action: printPowderLabel, text: 'Print Powder Label', tooltip: 'Prints a powder label', condition: 'printable', conditionValue: true },
        ]
      else
        actions = [
          {icon: <ArrowCircleUp />, action: openLoadToArm, actionType: 'object', text: 'Load to Arm', condition: 'loadable', conditionValue: true},
          {icon: <ArrowCircleDown />, action: (orderId: number | undefined) => doUnloadAllMolds(machine.name, orderId), text: 'Unload all molds', tooltip: 'Unloads all instances of this mold on this machine', condition: 'loaded', conditionValue: true},
          {icon: <ArrowBack />, action: doRemoveLoadQueue, text: 'Remove Load Queue', condition: 'in_loadqueue', conditionValue: true},
          {icon: <Print />, actionType: 'object', action: printPowderLabel, text: 'Print Powder Label', tooltip: 'Prints a powder label'}
        ]

    // if (canViewProduction)
    //   actions.push({icon: <LibraryBooks />, action: openProductionLogs, text: 'Production Logs', condition: 'color', conditionValue: 'loaded'})

    return actions
  }
  const handlePermissions = (permissions: any[]) => setPermissions(permissions)

  // row actions
  const openLoadToArm = (object: any) => {
    console.log('open load to arm :', object, 'id :', object.roto_scheduled_id ? object.roto_scheduled_id : object.arm_scheduled_id)
    if (object.loaded)
      setLoadToArmLoadingMore(true)
    else
      setLoadToArmLoadingMore(false)
    setLoadToArmId(object.arm_id)
    setLoadToArmModalName(`${object.number} ${object.item}`)
    setLoadToArmModalId(object.roto_scheduled_id ? object.roto_scheduled_id : object.arm_scheduled_id)
  }
  const closeLoadToArm = () => {
    setLoadToArmModalId(undefined)
    setLoadToArmModalName(undefined)
    setLoadToArmId(undefined)
  }

  const doLoadToArm = (data: any, callback: () => void) => {
    loadToArm(data, (data: any) => {
      console.log('load to machine callback :', data)
      closeLoadToArm()
      callback()
      refreshTable()
      setMachineDisplayLoading(true)
    })
  }
  const doRemoveLoadQueue = (orderId: number | undefined) => {
    if (orderId)
      removeLoadQueue(orderId, () => refreshTable())
  }
  const printPowderLabel = (object: any) => {
    const orderId: number = object.action_type === 'machineLoad' ? object.order_id : object.id
    if (orderId)
      getPowderLabelData(orderId, id, (data: any) => {
        for (const labelData of data) {
          console.log('label data :', labelData)
          printLabel(POWDER, labelData)
        }
    })
  }

  // general actions
  const doUnloadOrderItem = (orderName: string, armName: string, orderId: number | undefined) => {
    setRemoveOrderModalOrderId(orderId)
    setRemoveOrderModalOrder(orderName)
    setRemoveOrderModalArm(armName)
    setRemoveOrderModalOpen(true)
  }
  const doUnloadAllMolds = (machineName: string, orderId: number | undefined) => {
    unloadAllMolds(machineName, orderId, id, () => {
      refreshTable()
      setMachineDisplayLoading(true)
    })
  }
  const onRemoveOrderModalClose = (doUnload: boolean, status: any) => {
    console.log('status :', status)
    if (doUnload)
      unloadMold(removeOrderModalOrder, removeOrderModalArm, removeOrderModalOrderId, status, (data: any) => {
        setRemoveOrderModalOpen(false)
        console.log('unload mold data :', data)
        setRemoveOrderModalOrderId(undefined)
        refreshTable()

        if (data.load_after_items.length > 0) {
          setLoadAfterItems(data.load_after_items)
          setLoadAfterModalOpen(true)
        } else
          setMachineDisplayLoading(true)

        if (data.reload_object !== null) {
          openLoadToArm(data.reload_object)
        }
      })
    else
      setRemoveOrderModalOpen(false)
  }
  const unloadAllMoldsArm = (machineId: number, armId: number) => {
    let armName: string = ''
    for (const arm of arms) {
      if (arm.id === armId)
        armName = arm.name
    }

    doConfirm(`Unload all molds from ${armName}?`, () => {
      unloadAllMoldsFromArm(armId, () => {
        refreshTable()
        setRefreshMachineDisplay(!refreshMachineDisplay)
      })
    })
  }
  // setupsheet
  const viewSetupsheet = (orderItem: any, machineName: string) => {
    console.log(`viewSetupsheet(${orderItem.item}, ${machineName})`)
    setSetupsheetItemName(orderItem.item)
    setSetupsheetMachineName(machineName)
    setSetupsheetViewOpen(true)
  }
  const closeSetupsheetView = () => setSetupsheetViewOpen(false)

  // load after helpers
  const loadAfterModalClose = () => {
    setLoadAfterModalOpen(false)
    setRefreshMachineDisplay(!refreshMachineDisplay)
  }
  const doLoadAfter = () => {

    const ids = advancedScheduling ? loadAfterItems.map((lai: any) => lai.rotoScheduleId) : loadAfterItems.map((lai: any) => lai.id)

    loadAfter(ids, false, () => loadAfterModalClose())
  }

  const doLoadNothing = () => {

		const ids = advancedScheduling ? loadAfterItems.map((lai: any) => lai.rotoScheduleId) : loadAfterItems.map((lai: any) => lai.id)

    loadAfter(ids, true, () => loadAfterModalClose())
  }

  // prod logs
  const openProductionLogs = (id: number) => {
    setProductionLogsOrderId(id)
    setProductionLogsOpen(true)
  }
  const closeProductionLogs = () => {
    setProductionLogsOrderId(undefined)
    setProductionLogsOpen(false)
  }

  return (
    <>
      <PageTitle title={`Arm Loading ${getMachineNameDisplay()}`} />
      <BaseContent loading={machineDisplayLoading} permission={permissions?.view}>

        <ProcessingUserActions productivityEnabled={productivityEnabled} machineId={id} canView={canViewProductivity}
                               forceRefresh={false} />

        {arms.length > 0 ? <MachineLoadDisplay machine={machine} doRefresh={refreshMachineDisplay}
                                               unloadOrderItem={doUnloadOrderItem} removable
                                               advancedScheduling={advancedScheduling}
                                               unloadAllMoldsArm={unloadAllMoldsArm}
                                               doLoad permissions={permissions} notCollapsible
                                               loadToArmAction={doLoadToArm}
        /> : <></>}
      </BaseContent>

      {machine ?
        <ApiTable
          legend={getLegend()}
          objectName=''
          headers={['Order', 'Item', 'Description', 'Arm', 'Ship Date', 'Balance', 'Loaded', '']}
          rowFields={['number', 'item', 'description', 'arm', 'ship_date', 'balance', 'mold_load_status']}
          rowActions={getRowActions()}
          dataField='objects'
          url={`/scheduling/arm-loading/?machineId=${id}&tableData=${true}`}
          rowClickActions={[
            {field: 'item', action: (orderItem: number | undefined) => viewSetupsheet(orderItem, machine.name),
              condition: 'has_setupsheet', tooltip: 'View Setupsheet'}
          ]}
          searchable
          orderable
          preRefreshHook={() => setSuppressLoadDisplay(false)}
          refreshable
          refresh={machine.refresh}
          suppressLoadDisplay={suppressLoadDisplay}
          idField={advancedScheduling ? 'arm_scheduled_id' : 'id'}
          permissionsCallback={handlePermissions}
        />
        :
        <></>
      }

      <LoadToArmModal id={loadToArmModalId} orderName={loadToArmModalName} arms={arms} onClose={closeLoadToArm}
                      onSubmit={doLoadToArm} givenArm={loadToArmId ? loadToArmId : -1} canChoose={permissions?.edit}
                      loadingMore={loadToArmLoadingMore} />
      <LoadAfterModal open={loadAfterModalOpen} onClose={loadAfterModalClose} 
											doLoadAfter={doLoadAfter}
											doLoadNothing={doLoadNothing}
                      items={loadAfterItems} />
      <RemoveOrderConfirmModal arm={removeOrderModalArm} orderItem={removeOrderModalOrder} open={removeOrderModalOpen}
        doClose={onRemoveOrderModalClose} showRemoveAll={true} />
      <SetupsheetViewHelper open={setupsheetViewOpen} doClose={closeSetupsheetView} itemName={setupsheetItemName}
                            machineName={setupsheetMachineName} newTab />
      <ProductionLogModal open={productionLogsOpen} type={MOLDING} onClose={closeProductionLogs}
                          orderId={productionLogsOrderId} />
    </>
  )
}

export default ArmLoading