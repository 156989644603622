import React, { useRef } from 'react';
import { Box, Card, CardHeader, Typography, IconButton, Badge, useTheme, Skeleton, keyframes } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Machine } from './hooks/useMachineScheduling';

interface MachineListProps {
	machines: Machine[];
	selectedMachine: Machine | null;
	onSelectMachine: (machine: Machine) => void;
}

const MachineList: React.FC<MachineListProps> = ({
	machines,
	selectedMachine,
	onSelectMachine
}) => {
	const theme = useTheme();
	const scrollContainerRef = useRef<HTMLDivElement>(null);

	// Define the shimmer animation with more contrast
	const shimmer = keyframes`
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  `;

	// Custom loading skeleton style with higher contrast
	const skeletonStyles = {
		background: `linear-gradient(90deg, 
      ${theme.palette.grey[100]} 0%, 
      ${theme.palette.grey[300]} 20%, 
      ${theme.palette.grey[100]} 40%, 
      ${theme.palette.grey[300]} 60%,
      ${theme.palette.grey[100]} 80%,
      ${theme.palette.grey[300]} 100%)`,
		backgroundSize: '2000px 100%',
		animation: `${shimmer} 2.5s linear infinite`,
	};

	const scroll = (scrollOffset: number) => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollBy({ left: scrollOffset, behavior: 'smooth' });
		}
	};

	// Create array for loading placeholders
	const loadingCards = Array(20).fill(null);

	return (
		<Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', borderRadius: 3, boxShadow: 3, pt: 1, mt: 1 }}>
			<IconButton
				onClick={() => scroll(-800)}
				sx={{ mx: 1, position: 'absolute', left: 0, top: 30, zIndex: 3, bgcolor: 'background.paper', '&:hover': { bgcolor: 'action.hover' } }}
			>
				<ChevronLeftIcon />
			</IconButton>
			<Box
				sx={{
					position: 'relative',
					width: '100%',
					'&::before, &::after': {
						content: '""',
						position: 'absolute',
						top: 0,
						bottom: 0,
						width: '10px',
						zIndex: 2,
						pointerEvents: 'none',
					},
					'&::before': {
						left: 48,
						background: `linear-gradient(to right, ${theme.palette.background.paper}, ${theme.palette.background.paper}00)`,
					},
					'&::after': {
						right: 48,
						background: `linear-gradient(to left, ${theme.palette.background.paper}, ${theme.palette.background.paper}00)`,
					},
				}}
			>
				<Box
					ref={scrollContainerRef}
					sx={{
						display: 'flex',
						overflowX: 'auto',
						scrollBehavior: 'smooth',
						pb: 1,
						mx: 6,
						'&::-webkit-scrollbar': {
							height: '8px',
							px: 10
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: 'rgba(0,0,0,.2)',
							borderRadius: '4px',
						}
					}}
				>
					{machines.length === 0 ? (
						// Loading state
						loadingCards.map((_, index) => (
							<Box
								key={`loading-${index}`}
								sx={{
									position: 'relative',
									m: 1,
									flexShrink: 0,
									minWidth: 150,
									maxWidth: 300,
								}}
							>
								<Card
									sx={{
										width: 'auto',
										borderRadius: 3,
										boxShadow: 3,
										position: 'relative',
										zIndex: 1,
										backgroundColor: 'background.paper',
										border: '2px solid transparent',
										overflow: 'hidden', // Ensure shimmer doesn't overflow card borders
									}}
								>
									<CardHeader
										title={
											<Skeleton
												variant="text"
												width="80%"
												height={32}
												sx={skeletonStyles}
											/>
										}
										action={
											<Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
												<Skeleton
													variant="circular"
													width={30}
													height={30}
													sx={skeletonStyles}
												/>
											</Box>
										}
										sx={{
											p: 2,
											'& .MuiCardHeader-content': {
												flex: '1 1 auto',
											},
											'& .MuiCardHeader-action': {
												flex: '0 0 auto',
												alignSelf: 'center',
												marginTop: 0,
												marginBottom: 0,
											},
										}}
									/>
								</Card>
							</Box>
						))
					) : (
						// Actual machines
						machines.map((machine, index) => (
							<Box
								key={index}
								sx={{
									position: 'relative',
									m: 1,
									flexShrink: 0,
									minWidth: 150,
									maxWidth: 300,
								}}
							>
								<Card
									sx={{
										width: 'auto',
										cursor: 'pointer',
										borderRadius: 3,
										boxShadow: selectedMachine?.id === machine.id ? 5 : 3,
										transition: 'box-shadow 0.3s, border 0.3s',
										position: 'relative',
										zIndex: 1,
										backgroundColor: 'background.paper',
										border: selectedMachine?.id === machine.id ? '2px solid' : '2px solid transparent',
										borderColor: selectedMachine?.id === machine.id ? 'primary.main' : 'transparent',
									}}
									onClick={() => onSelectMachine(machine)}
								>
									<CardHeader
										title={
											<Typography noWrap variant="h6">{machine.name}</Typography>
										}
										action={
											<Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
												{machine.standalone_scheduled_orders.length > 0 && (
													<Badge
														badgeContent={machine.standalone_scheduled_orders.length}
														color="secondary"
														sx={{
															'& .MuiBadge-badge': {
																fontSize: '1rem',
																width: '30px',
																height: '30px',
																borderRadius: '50%',
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center',
																border: '2px solid',
																borderColor: 'background.paper',
															},
															mr: 2,
														}}
													/>
												)}
											</Box>
										}
										sx={{
											p: 2,
											'& .MuiCardHeader-content': {
												flex: '1 1 auto',
											},
											'& .MuiCardHeader-action': {
												flex: '0 0 auto',
												alignSelf: 'center',
												marginTop: 0,
												marginBottom: 0,
											},
										}}
									/>
								</Card>
							</Box>
						))
					)}
				</Box>
			</Box>
			<IconButton
				onClick={() => scroll(800)}
				sx={{ mx: 1, position: 'absolute', right: 0, top: 30, zIndex: 3, bgcolor: 'background.paper', '&:hover': { bgcolor: 'action.hover' } }}
			>
				<ChevronRightIcon />
			</IconButton>
		</Box>
	);
};

export default MachineList;