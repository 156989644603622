import React, { FC, useEffect } from 'react'
import { arrayMoveImmutable } from 'array-move'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { AxiosError } from 'axios'
// mui import
import Typography from '@mui/material/Typography'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
// mui icons
import CircularProgress from '@mui/material/CircularProgress'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
// custom imports
import DropdownActions from '../globalComps/DropdownActions'
import TableActions from './TableActions'
import BaseRow from './BaseRow'
import ExpandableRow from './ExpandableRow'
import SearchBar from '../globalComps/SearchBar'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import './rowHighlight.css'
import { colors } from '../colors'
import { api } from '../globals'
import { doAlert } from '../globalComps/PopupAlert/PopupAlert'
import Legend from '../globalComps/Legend'
import BaseTooltip from '../globalComps/BaseTooltip'
import Paper from '@mui/material/Paper'
import InfoText from "../globalComps/InfoText";

const SortableItem = SortableElement(({key, rowData}: {rowData: any, key: number | string}) => {
  return (
    <BaseRow
      key={key}
      id={rowData.id}
      data={rowData.data}
      object={rowData.object}
      tooltip={rowData.tooltip}
      highlight={rowData.highlight}
      actions={rowData.actions}
    />
  )
})
const SortableList = SortableContainer(({rows}: any) => {
  return (
    <TableBody>
      <>
        {rows.map((rowData: any, key: any) => (
          <SortableItem key={`item-${key}`} index={key} {...{rowData: rowData}} />
        ))}
      </>
    </TableBody>
  )
})

interface BaseBodyProps {
  sortable?: boolean
  sortableUrl?: any
  expandable?: boolean
  expandableParams?: any
  setExpandedId?: (id: number | undefined) => void
  rows: any[]
  refreshObject?: (object: any, create: boolean) => void
  updateDataOrder: (data: any[]) => void
  expandableCondition?: any
  expandableParentCondition?: any
}
const BaseBody: FC<BaseBodyProps> = ({sortable, sortableUrl, expandable,
                                       expandableParams, setExpandedId, rows, refreshObject,
                                       updateDataOrder, expandableCondition, expandableParentCondition}) => {

  const onSortEnd = ({oldIndex, newIndex}: {oldIndex: number, newIndex: number}) => {
    rows = arrayMoveImmutable(rows, oldIndex, newIndex)
    updateDataOrder(rows)
    console.log(`url=${sortableUrl.url} data :`, {oldIndex: oldIndex, newIndex: newIndex, sortParentId: sortableUrl.id})
    if (sortableUrl)
      api.post(sortableUrl.url, {oldIndex: oldIndex, newIndex: newIndex, sortParentId: sortableUrl.id})
        .catch((err: AxiosError) => {if (err.response) {doAlert(err.response.data.message, 'error', true)}})
  }

  useEffect(() => {}, [expandableParams])

  const breakColor: string = colors[useTheme().palette.mode].table.row.break
  const row = (data: any, key: number) => {
    if (expandable)
     return (
       <ExpandableRow
         key={key}
         parentRow={data}
         expandableParams={expandableParams}
         expandableCondition={expandableCondition}
         expandableParentCondition={expandableParentCondition}
       >
       </ExpandableRow>
     )
    else if (data === 'break')
      return (
        <tr key={key}>
          <td colSpan={Object.keys(rows[0].data).length + 1} style={{backgroundColor: breakColor, height: '10px'}}> </td>
        </tr>
      )
    else
      return (
        <BaseRow
          color={data.color}
          key={key}
          id={data.id}
          data={data.data}
          object={data.object}
          tooltip={data.tooltip}
          highlight={data.highlight}
          actions={data.actions}
          setExpandedId={setExpandedId}
          refreshObject={refreshObject}
        />
      )
  }

  if (sortable)
    return (
      <>
        <SortableList onSortEnd={onSortEnd} useWindowAsScrollContainer {...{rows: rows}} />
      </>
    )
  else
    return (
      <TableBody>
        {rows.length > 0 ? rows.map((rowData: any, key: number) => row(rowData, key)) : <></>}
      </TableBody>
    )
}

interface BaseTableProps {
  name: string | undefined
  titleVariant: any
  headers: any[]
  rows: any[]
  actions: any[]
  clickActions?: any[]
  search?: (q: string) => void
  searchPlaceholder?: string
  childSearch?: (q: string) => void,
  childSearchPlaceholder?: string
  orderable?: boolean
  orderableBlacklist?: string[]
  orderFunc?: (text: string, ascending: boolean) => void
  orderStatus?: any
  sortable?: boolean
  sortableUrl?: any
  expandable?: boolean
  expandableActions?: any[]
  expandableParams?: any
  setExpandedId?: (id: number | undefined) => void
  refreshObject?: (object: any, create: boolean) => void
  q: string
  childQ: string
  bottomLoading: boolean
  filterable?: boolean
  filterButtonText?: string
  filterOptions?: any[]
  updateDataOrder: (data: any[]) => void
  expandableCondition?: any
  expandableParentCondition?: any
  legend?: any[]
  totalItemCount?: number
  filterForm?: JSX.Element
  currentPage?: number
  totalPages?: number
  hidePages?: boolean | undefined
  tableHeadText?: string | undefined
}
/**
 * Base table component
 * @param name
 * @param titleVariant
 * @param sortable
 * @param headers
 * @param rows
 * @param actions
 * @param search
 * @param searchPlaceholder
 * @param expandable
 * @param expandableActions
 * @param expandableParams?
 * @param q
 * @param bottomLoading
 * @param filterable?
 * @param filterButtonText?
 * @param filterOptions?
 * @param hidePages? hides the page: #/# display at the bottom of the table
 * @param tableHeadText: text to display above the table
 */
const BaseTable: FC<BaseTableProps> = ({
	name,
	titleVariant,
	sortable,
	sortableUrl,
	orderable,
	orderFunc,
	orderStatus,
	headers,
	rows,
	actions,
	clickActions,
	search,
	childSearch,
	searchPlaceholder,
	childSearchPlaceholder,
	refreshObject,
	expandable,
	expandableParams,
	setExpandedId,
	q,
	childQ,
	bottomLoading,
	filterable,
	filterButtonText,
	filterOptions,
	updateDataOrder,
	orderableBlacklist,
	expandableCondition,
	expandableParentCondition,
	legend,
	totalItemCount,
	filterForm,
	currentPage,
	totalPages,
	hidePages,
	tableHeadText
}) => {
	const theme = useTheme();

	// Search bar components
	const searchBar = search ? (
		<SearchBar
			outlined={true}
			width={200}
			onSearch={search}
			q={q}
			defaultText={searchPlaceholder}
		/>
	) : null;

	const childSearchBar = childSearch ? (
		<SearchBar
			outlined={true}
			width={200}
			onSearch={childSearch}
			q={childQ}
			defaultText={childSearchPlaceholder}
		/>
	) : null;

	// Filter dropdown component
	const filterDropdown = (filterable && filterButtonText && filterOptions && filterOptions.length > 0) ? (
		<DropdownActions name={filterButtonText} noMin>
			{filterOptions.map((filter) => (
				<MenuItem
					onClick={() => filter.action(filter.text)}
					key={filter.text}
					sx={{ fontSize: '16px' }}
				>
					<ListItemText>{filter.text}</ListItemText>
				</MenuItem>
			))}
		</DropdownActions>
	) : null;

	// Header cell component
	const headerCell = (text: string, key: number, tooltip: string | undefined) => {
		const isOrderable = orderable && text && text !== '' &&
			(!orderableBlacklist?.includes(text));

		if (!isOrderable) {
			return tooltip ? (
				<BaseTooltip key={key} text={tooltip}>
					<TableCell align='center'>{text}</TableCell>
				</BaseTooltip>
			) : (
				<TableCell align='center' key={key}>{text}</TableCell>
			);
		}

		const OrderIcon = orderStatus?.headerText === text && !orderStatus.ascending ?
			ArrowDropUp : ArrowDropDown;

		const cell = (
			<TableCell align='center' key={key}>
				{text}
				<IconButton
					size='small'
					onClick={() => orderFunc?.(text, !orderStatus?.ascending)}
					sx={orderStatus?.headerText === text ? {
						color: theme.palette.primary.main
					} : undefined}
				>
					<OrderIcon />
				</IconButton>
			</TableCell>
		);

		return tooltip ? (
			<BaseTooltip key={key} text={tooltip}>{cell}</BaseTooltip>
		) : cell;
	};

	return (
		<Box sx={{ width: '100%' }}>
			{/* Title Row */}
			{name && (
				<Box sx={{ mb: 2 }}>
					<Typography variant={titleVariant}>{name}</Typography>
				</Box>
			)}

			{/* Controls Row */}
			<Grid
				container
				spacing={2}
				alignItems="center"
				sx={{ mb: 2 }}
			>
				{/* Left Section */}
				<Grid item>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
						{/* Filter Section */}
						{(filterable || filterForm) && (
							<>
								{filterDropdown}
								{filterForm}
							</>
						)}
					</Box>
				</Grid>

				{/* Center Section */}
				<Grid item xs>
					{/* Legend Section */}
					{legend && (
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<Legend legendItems={legend} />
						</Box>
					)}
				</Grid>

				{/* Right Section - Updated with better spacing */}
				<Grid item>
					<Box sx={{
						display: 'flex',
						alignItems: 'center',
						gap: 2,  // Consistent gap between all elements
					}}>
						{/* Actions Section */}
						{actions?.length > 0 && (
							<Box sx={{  }}> 
								<TableActions actions={actions} />
							</Box>
						)}

						{/* Search Section */}
						{(search || childSearch) && (
							<Box sx={{
								display: 'flex',
								gap: 2,
								minWidth: 'fit-content',  
							}}>
								{searchBar}
								{childSearchBar}
							</Box>
						)}
					</Box>
				</Grid>
			</Grid>

			{/* Info Text */}
			{(sortable || tableHeadText || totalItemCount) && (
				<Box sx={{ mb: 2 }}>
					{sortable && (
						<InfoText text='This table is sortable, drag and drop rows to re-order the data.' />
					)}
					{tableHeadText && (
						<InfoText text={tableHeadText} />
					)}
					{!!totalItemCount && (
						<Typography>Total items: {totalItemCount}</Typography>
					)}
				</Box>
			)}

			{/* Table */}
			<TableContainer component={Paper} sx={{ borderRadius: 3 }}>
				<Table size='small'>
					<TableHead sx={{
						backgroundColor: theme.palette.mode === 'dark' ?
							colors.dark.table.header :
							colors.light.table.header
					}}>
						<TableRow>
							{headers.map((header: any, key: number) =>
								headerCell(
									typeof header === 'string' ? header : header.text,
									key,
									typeof header === 'string' ? undefined : header.tooltip
								)
							)}
						</TableRow>
					</TableHead>
					<BaseBody
						expandable={expandable}
						expandableParams={expandableParams}
						setExpandedId={setExpandedId}
						sortable={sortable}
						sortableUrl={sortableUrl}
						rows={rows}
						refreshObject={refreshObject}
						updateDataOrder={updateDataOrder}
						expandableCondition={expandableCondition}
						expandableParentCondition={expandableParentCondition}
					/>
				</Table>
			</TableContainer>

			{/* Pagination */}
			{currentPage && totalPages && !hidePages && (
				<Typography
					variant='subtitle2'
					sx={{ pt: 1, textAlign: 'center' }}
				>
					Page: {currentPage === 1 ? currentPage : currentPage - 1} / {totalPages}
				</Typography>
			)}

			{/* Loading Indicator */}
			{bottomLoading && (
				<Box sx={{ display: 'flex', mt: 2 }}>
					<CircularProgress sx={{ margin: '0 auto' }} color='inherit' />
				</Box>
			)}
		</Box>
	);
};

export default BaseTable;
