import { DependenciesConfig, DateHelper, EventTooltipConfig, SchedulerConfig, TimeRangesConfig } from "@bryntum/scheduler";

/****** Global Variables *******/
const ROW_HEIGHT: number = 45;
const ROW_MARGIN: number = 5;
const START_DATE = new Date();

// Working hours configuration
const WORKING_START_HOUR = 8;
const WORKING_END_HOUR = 17;

// Weekend configuration
export interface WorkingTimeConfig {
	excludeWeekends: boolean;
	workingDays: number[];
	workingStartHour: number;
	workingEndHour: number;
}

export const DEFAULT_WORKING_TIME: WorkingTimeConfig = {
	excludeWeekends: true,
	workingDays: [1, 2, 3, 4, 5, 6], // Monday to Friday (some bug in bryntum?)
	workingStartHour: WORKING_START_HOUR,
	workingEndHour: WORKING_END_HOUR
};

// Function to create scheduler config based on working time settings
export const createSchedulerConfig = (workingTime: WorkingTimeConfig): Partial<SchedulerConfig> => ({
	startDate: START_DATE,
	rowHeight: ROW_HEIGHT,
	barMargin: ROW_MARGIN,
	multiEventSelect: true,
	autoHeight: true,
	eventStyle: undefined,
	createEventOnDblClick: false,
	zoomOnTimeAxisDoubleClick: false,
	// Only apply working time if weekends are excluded
	workingTime: workingTime.excludeWeekends ? {
		fromDay: Math.min(...workingTime.workingDays),
		toDay: Math.max(...workingTime.workingDays),
		// fromHour: workingTime.workingStartHour,
		// toHour: workingTime.workingEndHour
	} : undefined
});

const dependencyConfig: Partial<DependenciesConfig> = {
	allowCreate: false
};

const timeRangesConfig: Partial<TimeRangesConfig> = {
	showCurrentTimeLine: {
		name: "Now",
	},
	currentTimeLineUpdateInterval: 86400000,
	showHeaderElements: true,
	enableResizing: true,
	showTooltip: true,
	callOnFunctions: true,
};

const eventTooltipConfig: Partial<EventTooltipConfig> = {
	template: (data: any) => {
		if (data.eventRecord.work_order_number) {
			return `
        <dl style="margin-top: 5px; margin-bottom: 5px; border-radius: 50px">
          <dt>
            <strong>Order: ${data.eventRecord.work_order_number} | ${data.eventRecord.balance} Parts</strong>
          </dt>
          <dt><strong>Item: ${data.eventRecord.data.item} | ${data.eventRecord.mold_load_quantity} Mold(s)</strong></dt>
          <dt style="margin-bottom: 5px">${data.eventRecord.data.description.trim()}</dt>
          <dd>
            Start: ${DateHelper.format(data.eventRecord.startDate, "hh:mm A MM/DD")}
          </dd>
          <dd>
            End: ${DateHelper.format(data.eventRecord.endDate, "hh:mm A MM/DD")}
          </dd>
        </dl>`.trim();
		} else {
			return `<dl style="margin-top: 5px; margin-bottom: 5px">
      <dt style="margin-bottom: 5px">
        <strong>Counter Weight</strong>
      </dt>
      <dd>
        Start: ${DateHelper.format(data.eventRecord.startDate, "hh:mm A MM/DD")}
      </dd>
      <dd>
        End: ${DateHelper.format(data.eventRecord.endDate, "hh:mm A MM/DD")}
      </dd>
      </dl>`;
		}
	}
};

const schedulerFeatures = {
	dependencies: dependencyConfig,
	timeRanges: timeRangesConfig,
	eventTooltip: eventTooltipConfig,
};

export {
	ROW_HEIGHT,
	ROW_MARGIN,
	START_DATE,
	WORKING_START_HOUR,
	WORKING_END_HOUR,
	schedulerFeatures
};