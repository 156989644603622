import React, { FC, useEffect, useState } from 'react';
// mui imports
import { useTheme } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import MessageIcon from '@mui/icons-material/Message';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Divider from '@mui/material/Divider';
// custom imports
import { colors } from "../colors";
import { acknowledgeMessage, getLatestMessage, hideMessage } from "../modals/ChannelModal/ChannelModalApi";
import ChannelModal from "../modals/ChannelModal/ChannelModal";

interface ChannelActionProps {
	text: string;
	icon?: React.ReactNode;
	action?: () => void;
}

const ChannelAction: FC<ChannelActionProps> = ({ text, icon, action }) => {
	return (
		<Button
			onClick={action ? action : () => { }}
			startIcon={icon}
			sx={{
				fontSize: '0.875rem',
				textTransform: 'none',
				color: 'text.secondary',
				'&:hover': {
					backgroundColor: 'action.hover',
				},
				px: 2
			}}
		>
			{text}
		</Button>
	);
}

interface ChannelProps {
	armId?: number;
	linePlanId?: number;
	shipping?: boolean | undefined
	forceRefresh?: boolean | undefined
}

const Channel: FC<ChannelProps> = ({
	armId,
	linePlanId,
	shipping,
	forceRefresh
}) => {
	const theme = useTheme();
	const [latestChannelMessage, setLatestChannelMessage] = useState<string>('');
	const [latestChannelMessageId, setLatestChannelMessageId] = useState<number | undefined>(undefined);
	const [visible, setVisible] = useState<boolean>(false);
	const [messageUser, setMessageUser] = useState<string>('');
	const [messageDatetime, setMessageDatetime] = useState<string>('');
	const [channelModalArmId, setChannelModalArmId] = useState<number | undefined>(undefined);
	const [channelModalLinePlanId, setChannelModalLinePlanId] = useState<number | undefined>(undefined);
	const [acknowledged, setAcknowledged] = useState<boolean>(false);
	const [refresh, setRefresh] = useState<boolean>(false);

	const refreshChannel = () => setRefresh(!refresh);

	useEffect(() => {
		let isActive: boolean = true;

		if ((armId !== undefined || linePlanId !== undefined || shipping) && isActive)
			getLatestChannelMessage();

		return () => { isActive = false };
	}, [armId, refresh, forceRefresh])

	const getLatestChannelMessage = () => {
		getLatestMessage(armId, linePlanId, shipping, (message: string, user: string, datetime: string, id: number, acknowledged: boolean) => {
			if (message && message !== '') {
				setVisible(true)
				setLatestChannelMessage(message);
				setMessageUser(user);
				setLatestChannelMessageId(id);
				setMessageDatetime(datetime);
				setAcknowledged(acknowledged)
			} else {
				setVisible(false);
			}
		});
	}

	const doAcknowledgeMessage = () => {
		if (latestChannelMessageId) acknowledgeMessage(latestChannelMessageId, () => refreshChannel());
	}

	const openChannel = () => {
		setChannelModalArmId(armId);
		setChannelModalLinePlanId(linePlanId);
	}

	const closeChannel = () => {
		setChannelModalArmId(undefined);
		setChannelModalLinePlanId(undefined);
	}

	const doHideMessage = () => {
		if (latestChannelMessageId)
			hideMessage(latestChannelMessageId, () => {
				refreshChannel();
			})
	}

	if (!visible) return null;

	return (
		<>
			<Box sx={{ px: 2, my: 1.5, width: '100%' }}>
				<Paper
					elevation={2}
					sx={{
						borderRadius: 3,
						overflow: 'hidden',
						backgroundColor: colors[theme.palette.mode].channel.background,
						border: `1px solid ${theme.palette.divider}`,
					}}
				>
					<Box sx={{ p: 2 }}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={9}>
								<Typography
									variant="body1"
									sx={{
										color: theme.palette.text.primary,
										wordBreak: 'break-word'
									}}
								>
									{latestChannelMessage}
								</Typography>
							</Grid>
							<Grid item xs={12} md={3}>
								<Typography
									variant="caption"
									sx={{
										color: theme.palette.text.secondary,
										display: 'block',
										textAlign: { xs: 'left', md: 'right' }
									}}
								>
									{messageUser} • {messageDatetime}
								</Typography>
							</Grid>
						</Grid>
					</Box>

					<Divider />

					<Box
						sx={{
							px: 2,
							py: 1,
							display: 'flex',
							gap: 1,
							flexWrap: 'wrap',
							backgroundColor: theme.palette.mode === 'dark'
								? 'rgba(255, 255, 255, 0.02)'
								: 'rgba(0, 0, 0, 0.01)',
						}}
					>
						<ChannelAction
							text="Open Channel"
							icon={<MessageIcon fontSize="small" />}
							action={openChannel}
						/>
						{!acknowledged && (
							<ChannelAction
								text="Acknowledge Message"
								icon={<CheckCircleIcon fontSize="small" />}
								action={doAcknowledgeMessage}
							/>
						)}
						<ChannelAction
							text="Hide Message"
							icon={<VisibilityOffIcon fontSize="small" />}
							action={doHideMessage}
						/>
					</Box>
				</Paper>
			</Box>
			<ChannelModal
				armId={channelModalArmId}
				linePlanId={channelModalLinePlanId}
				channelClose={closeChannel}
			/>
		</>
	);
}

export default Channel;