import { useEffect, useMemo, useState } from "react";
import useWebsocket from "../../../../../../websocket/hooks/useWebsocket";

export interface InboundSync {
	created: string
	orders_created: number
	id: number
}

export interface OutboundSync {
	created: string
	total_units_synced: string
	processed_bundles: string
	status: 'pending' | 'success' | 'failed'
	id: number
}

export interface TokenField {
	label: string
	field: string
}

interface ScheduleInfo {
	next_run?: string 
}

interface OrderSync {
	page_state: any
	schedulable: Boolean
	inbound_sync_enabled: Boolean
	outbound_sync_enabled: Boolean
	inbound_syncs: InboundSync[]
	outbound_syncs: OutboundSync[]
	token_form: TokenField[]
	is_scheduled: Boolean
	next_run: string | null
}

const useOrderSync = () => {
	const [data, setData] = useState<OrderSync>({
		page_state: {},
		schedulable: false,
		inbound_sync_enabled: true,
		outbound_sync_enabled: false,
		inbound_syncs: [],
		outbound_syncs: [],
		token_form: [], 
		is_scheduled: false,
		next_run: null
	});

	const { loading, sendMessage, isVisible, setPageState, isReady } = useWebsocket('/orders/sync/', setData);

	let pageState: any = data.page_state;
	const schedulable = useMemo(() => data.schedulable ? data.schedulable : false, [data]);
	const inbound_sync_enabled = useMemo(() => data.inbound_sync_enabled ? data.inbound_sync_enabled : false, [data]);
	const inbound_syncs = useMemo(() => data.inbound_syncs ? data.inbound_syncs : [], [data]);
	const outbound_sync_enabled = useMemo(() => data.outbound_sync_enabled ? data.outbound_sync_enabled : false, [data]);
	const outbound_syncs = useMemo(() => data.outbound_syncs ? data.outbound_syncs : [], [data]);
	const tokenForm = useMemo(() => data.token_form ? data.token_form : [], [data]);
	const isScheduled = useMemo(() => data.is_scheduled ? data.is_scheduled : false, [data]);
	const nextRun = useMemo(() => data.next_run ? data.next_run : null, [data]);

	return {
		loading, sendMessage, isVisible, setPageState,
		pageState, isReady, schedulable,
		inbound_sync_enabled, inbound_syncs, 
		outbound_sync_enabled, outbound_syncs,
		tokenForm, 
		isScheduled, nextRun
	};
};

export default useOrderSync;
