import React, { useMemo, useState } from 'react';
import PageTitle from '../../../../utils/globalComps/PageTitle';
import BaseContent from '../../../../utils/globalComps/BaseContent';
import BaseAccordion from '../../../../utils/globalComps/BaseAccordion';
import useStatistics from './hooks/useStatistics';
import OverallStatistics from './tables/OverallStatisticsTable';
import { ArmLogBars, LoggedProductivityBars } from './ProductivityBars';
import OrdersProcessed from './tables/OrdersProccessedTable';
import { Grid, Typography, Button, Box, Tooltip, useTheme } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AssemblyStatistics, MachineState, MachineStatistics, PostMoldingStatistics, MachineProcessedOrder, LineplanProcessedOrder } from './hooks/Interfaces';

const Statistics = ({ }) => {
	document.title = 'Production Statistics | RotoEdgePro';
	const { 
		loading, sendMessage, pageState, 
		machineStats, postMoldingStats, assemblyStats, 
		combineProcessedOrders
	} = useStatistics();
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);
	const [view, setView] = useState<'molding' | 'post_molding' | 'assembly'>('molding');
	const activeStats = useMemo(() => {
		let stats: {
			[key: string]: MachineStatistics | PostMoldingStatistics | AssemblyStatistics 
		} = {}
		switch (view) {
			case 'molding':
				stats = machineStats
				break;
			case 'post_molding':
				stats = postMoldingStats
				break;
			case 'assembly':
				stats = assemblyStats
				break;
		}
		return stats
	}, [view, machineStats, postMoldingStats, assemblyStats])

	const theme = useTheme()

	const handleViewChange = (
		event: React.MouseEvent<HTMLElement>,
		view: 'molding' | 'post_molding' | 'assembly',
	) => {
		if (view !== null) {
			setView(view);
			sendMessage('set_production_view', { view })
		}
	};

	const handleDateChange = (date: Date | null) => {
		const dateString = date ? date.toISOString() : null;
		pageState.date = dateString;
		setSelectedDate(date);
		sendMessage('change_date', { date: dateString }, true);
	}

	const onAccordionChange = (event: React.SyntheticEvent, isExpanded: boolean, machine: string) => {
		const machineState = pageState[machine];
		if (machineState && typeof machineState !== 'string') {
			(machineState as MachineState).active = isExpanded;
			sendMessage('update_page_state', { page_state: pageState });
		}
	};

	const handleExport = () => {
		const wb = XLSX.utils.book_new();

		const combinedOrders = combineProcessedOrders(activeStats);
		const ordersArray = Object.values(combinedOrders);

		if (ordersArray.length > 0) {
			const ws = XLSX.utils.json_to_sheet(ordersArray, {
				header: [
					'order', 'item', 'ship_date', 'balance',
					'conforming_parts', 'non_conforming_parts', 'skipped_count'
				]
			});
			XLSX.utils.sheet_add_aoa(ws, [[
				'Order', 'Item', 'Ship Date', 'Balance',
				'Conforming Parts', 'Non Conforming Parts', 'Skipped Count'
			]], { origin: 'A1' });
			XLSX.utils.book_append_sheet(wb, ws, 'Combined Orders');
		}

		const formattedDate = selectedDate ? selectedDate.toISOString().split('T')[0] : 'no_date';
		const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `Processed_Orders_${formattedDate}.xlsx`);
	};

	const renderContent = () => {
		switch (view) {
			case 'molding':
				return (
					<div style={{ marginTop: '20px' }}>
						{/* Existing molding content */}
						{Object.keys(activeStats).map((machine: any, key: number) => (
							<BaseAccordion title={machine} key={key} onChange={(event, isExpanded) => onAccordionChange(event, isExpanded, machine)}>
								<Grid>
									<Grid>
										<ArmLogBars armLogData={(activeStats[machine] as MachineStatistics).arm_log_bars} />
									</Grid>
									<Grid>
										<Typography variant='h6' paddingLeft={1}>Overall</Typography>
										<OverallStatistics data={activeStats[machine].overall} view={view} />
									</Grid>
									<Grid sx={{ marginTop: 3 }}>
										<OrdersProcessed orderData={activeStats[machine].processed_orders} view={view} />
									</Grid>
								</Grid>
							</BaseAccordion>
						))}
					</div>
				);
			case 'post_molding':
				return (
					<div style={{ marginTop: '20px' }}>
						{Object.keys(activeStats).map((lineplan: any, key: number) => (
							<BaseAccordion title={lineplan} key={key} onChange={(event, isExpanded) => onAccordionChange(event, isExpanded, lineplan)}>
								<Grid>
									<Grid>
										<LoggedProductivityBars loggedProductivity={(activeStats[lineplan] as PostMoldingStatistics).logged_productivity} />
									</Grid>
									<Grid>
										<Typography variant='h6' paddingLeft={1}>Overall</Typography>
										<OverallStatistics data={activeStats[lineplan].overall} view={view} />
									</Grid>
									<Grid sx={{ marginTop: 3 }}>
										<OrdersProcessed orderData={activeStats[lineplan].processed_orders} view={view} />
									</Grid>
								</Grid>
							</BaseAccordion>
						))}
					</div>
				);
			case 'assembly':
				return (
					<div style={{ marginTop: '20px' }}>
						{Object.keys(activeStats).map((lineplan: any, key: number) => (
							<BaseAccordion title={lineplan} key={key} onChange={(event, isExpanded) => onAccordionChange(event, isExpanded, lineplan)}>
								<Grid>
									<Grid>
										<LoggedProductivityBars loggedProductivity={(activeStats[lineplan] as PostMoldingStatistics).logged_productivity} />
									</Grid>
									<Grid>
										<Typography variant='h6' paddingLeft={1}>Overall</Typography>
										<OverallStatistics data={activeStats[lineplan].overall} view={view} />
									</Grid>
									<Grid sx={{ marginTop: 3 }}>
										<OrdersProcessed orderData={activeStats[lineplan].processed_orders} view={view} />
									</Grid>
								</Grid>
							</BaseAccordion>
						))}
					</div>
				);
			default:
				return <></>;
		}
	};

	return (
		<>
			<PageTitle title='Production Statistics' />
			<BaseContent loading={loading}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
					<Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DatePicker
								label="Select Date"
								value={selectedDate}
								onChange={(date) => handleDateChange(date)}
								sx={{
									'& .MuiOutlinedInput-root': {
										borderRadius: 3
									}
								}}
								slotProps={{
									popper: {
										sx: {
											'& .MuiPaper-root': {
												borderRadius: 3,
												mt: 1
											}
										}
									}
								}}
							/>
						</LocalizationProvider>
					</Box>
					<ToggleButtonGroup
						value={view}
						exclusive
						onChange={handleViewChange}
						aria-label="view selector"
						sx={{
							gap: '16px',
							'& .MuiToggleButton-root': {
								border: 'none',
								borderRadius: '24px',
								px: 4,
								py: 1,
								color: 'text.secondary',
								backgroundColor: 'transparent',
								textTransform: 'uppercase',
								fontWeight: 500,
								letterSpacing: '0.5px',
								fontSize: '1rem',
								'&:hover': {
									backgroundColor: 'rgba(0, 0, 0, 0.04)'
								},
								'&.Mui-selected': {
									backgroundColor: theme.palette.primary.main,
									color: 'white',
									'&:hover': {
										backgroundColor: theme.palette.primary.light,
									},
								},
							},
						}}
					>
						<ToggleButton value="molding">
							Molding
						</ToggleButton>
						<ToggleButton value="post_molding">
							Post Molding
						</ToggleButton>
						<ToggleButton value="assembly">
							Assembly
						</ToggleButton>
					</ToggleButtonGroup>
					<Tooltip title='A date must be selected to export processed orders. Only machines that have been opened will be included in the export.'>
						<span>
							<Button
								variant="contained"
								color="primary"
								onClick={handleExport}
								disabled={!selectedDate}
								sx={{ height: 50, borderRadius: 3 }}
							>
								Export Orders Processed
							</Button>
						</span>
					</Tooltip>
				</Box>
				{renderContent()}
			</BaseContent>
		</>
	);
};

export default Statistics;