// React imports
import React, { FC, useState, useEffect } from 'react';
// MUI imports
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
// Custom imports
import Login from '../login/login';
import NavHeader from '../navheader/navheader';
import { AuthService } from '../../utils/auth';
import { api } from '../../utils/globals';
import Confirm from '../../utils/modals/Confirm';
import PopupAlert from '../../utils/globalComps/PopupAlert/PopupAlert';

const authService = new AuthService();



// Extend the background palette interface
declare module '@mui/material/styles' {

	interface Palette {
		background: TypeBackground;
		table: TypeTable;
		color: TypeColor;
	}

	interface PaletteOptions {
		background?: Partial<TypeBackground>;
		table?: Partial<TypeTable>;
		color?: Partial<TypeColor>
	}

	interface TypeBackground {
		hover: string;
	}

	interface TypeTable {
		stickyHeader: string
	}

	interface TypeColor {
		green: string
		yellow: string
		orange: string
		blue: string
		purple: string
		red: string
		grey: string
		teal: string
	}

	
}

/**
 * Main comp
 * @constructor
 */
const Main: FC = () => {
  const [authed, setAuthed] = useState<boolean>(false);
  const [themeMode, setThemeMode] = useState<string>('light');
  const [loading, setLoading] = useState<boolean>(true);

	// light colors are at 75% lightness: https://www.w3schools.com/colors/colors_picker.asp
	const lightTheme = createTheme({
		palette: {
			mode: 'light',
			background: {
				default: '#f4f9ff',
				paper: '#ffffff',
				hover: '#ededed',
			},
			primary: { main: '#178de0' },
			action: {
				hover: '#d0d0d0',
				selected: '#c0c0c0',
				disabled: '#a0a0a0',
			}, 
			table: {
				stickyHeader: '#f4f9ff',
			}, 
			color: {
				green: '#90EE90',
				yellow: '#eadc96',
				orange: '#ffb84d',
				blue: '#96d2ea',
				purple: '#EABFFF',
				red: '#fa6b6b',
				grey: '#bfbfbf', 
				teal: '#98D7C2'
			}
		},
	});

	// usually 25% darkness from light color on: https://www.w3schools.com/colors/colors_picker.asp
	const darkTheme = createTheme({
		palette: {
			mode: 'dark',
			background: {
				default: '#121212',
				paper: '#1e1e1e',
				hover: '#333333',
			},
			primary: { main: '#90caf9' },
			action: {
				hover: '#424242',
				selected: '#616161',
				disabled: '#757575',
			},
			table: {
				stickyHeader: '#121212'
			}, 
			color: {
				green: '#116e11',
				yellow: '#6a5c15',
				orange: '#b36b00',
				blue: '#15526a',
				purple: '#550080',
				red: '#863434',
				grey: '#404040', 
				teal: '#167D7F'
			}
		},
	});

  // handles login/logout
  const handleLogin = () => setAuthed(true)
  const handleLogout = () => {
    setAuthed(false)
    authService.logout()
  };

  // Toggle theme change
  const handleThemeChange = (theme: string) => setThemeMode(theme);

  // Check if cached token, auto login if so
  useEffect(() => {

    let isActive = true;

    if (isActive)
      api.get('/auth/token/check')
        .then((resp: any) => {
          setLoading(false)
          setAuthed(true)
        })
        .catch((err: any) => {
          setLoading(false)
          setAuthed(false)
        });

    return () => {isActive = false}
  }, []);

  return (
    <ThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
      {loading ?
        <Box sx={{display: 'flex', textAlign: 'center'}}>
          <CircularProgress sx={{margin: '0 auto 1rem'}} color='inherit' />
        </Box>
      :
        authed ?
          <>
            <CssBaseline />
            <NavHeader doLogout={handleLogout} setTheme={handleThemeChange} />
          </>
          :
          <Login onLoginSuccess={handleLogin} />}
      <Confirm />
      <PopupAlert />
    </ThemeProvider>
  );
}

export default Main;
