import React, { FC, useState } from 'react'
// mui imports
import Grid from '@mui/material/Grid'
// mui icons
import Message from '@mui/icons-material/Message'
import LibraryBooks from '@mui/icons-material/LibraryBooks'
// custom imports
import {ASSEMBLY_LP, generateBomPdf, useEffectApi} from '../../../utils/globals'
import { getChildItemLogData, getLineplanPageData, getLogData, getProcessInstructions, getPullBomData, logProduction } from './LineplanApi'
import { logTypeFromLineType } from '../../../utils/globalComps/ProductionLog/ProductionLogHelpers'
import { printLabel } from '../../../utils/labelPrinting/labelPrinting'
import BaseContent from '../../../utils/globalComps/BaseContent'
import PageTitle from '../../../utils/globalComps/PageTitle'
import LineplanLogTable from './LineplanLogTable'
import PdfViewer from '../../../utils/modals/PdfViewer'
import LogProduction from '../../../utils/globalComps/LogProduction/LogProduction'
import LineplanLiveTable from '../../../utils/globalComps/LineplanLiveTable/LineplanLiveTable'
import BaseAccordion from '../../../utils/globalComps/BaseAccordion'
import Channel from '../../../utils/globalComps/Channel'
import ChannelModal from '../../../utils/modals/ChannelModal/ChannelModal'
import ProductionLogModal from '../../../utils/globalComps/ProductionLog/ProductionLogModal'
import ProcessingUserActions from '../../../utils/globalComps/ProcessingUserActions/ProcessingUserActions'
import ScheduleToLineplan from "../../../utils/globalComps/ScheduleToLineplan/ScheduleToLineplan";
import './lineplan.css'
import LineplanPdfView from '../management/setupsheets/LineplanPdfView'

const LOG: number = 1
const LINE: number = 2

interface LineplanProps {
  type: number
  typeName: string
  id: number
}
/**
 * Lineplan page, holds respective lineplan live table, and then global orders with an available amount table
 * @param lineplan id
 */
const LineplanProcessing: FC<LineplanProps> = ({type, typeName, id}) => {

  const [loading, setLoading] = useState<boolean>(true)
  const [title, setTitle] = useState<string>(typeName)
  const [logType, setLogType] = useState<number>(LOG)
  const [logNCOnly, setLogNCOnly] = useState<boolean>(false)
  const [suppressLogLoad, setSuppressLogLoad] = useState<boolean>(false)
  const [refreshLog, setRefreshLog] = useState<boolean>(false)
  const [refreshLine, setRefreshLine] = useState<boolean>(false)
  const [productivityEnabled, setProductivityEnabled] = useState<boolean>(false)
  const [productivityRefresh, setProductivityRefresh] = useState<boolean>(false)
  const [shifts, setShifts] = useState<any[]>([])
  const [scrapCodeList, setScrapCodeList] = useState<any[]>([])

  const [canViewProductivity, setCanViewProductivity] = useState<boolean>(false)
  const [canViewProduction, setCanViewProduction] = useState<boolean>(false)
  const [canLogProduction, setCanLogProduction] = useState<boolean>(false)
  const [canViewChannel, setCanViewChannel] = useState<boolean>(false)

  const [processInstructionsPdf, setProcessInstructionsPdf] = useState<any | undefined>(undefined)
  const [processInstructionsItemId, setProcessInstructionsItemId] = useState<number | undefined>(undefined)
  const [logProductionItem, setLogProductionItem] = useState<any[]>([])  // this list will only have one object in it
  const [logProductionErrors, setLogProductionErrors] = useState<any[]>([])
  const [channelId, setChannelId] = useState<number | undefined>(undefined)
  const [productionLogsOpen, setProductionLogsOpen] = useState<boolean>(false)
  const [productionLogsOrderId, setProductionLogsOrderId] = useState<number | undefined>(undefined)
  const [stageToLineplanId, setStageToLineplanId] = useState<number | undefined>(undefined)

  const [refreshChannel, setRefreshChannel] = useState<boolean>(false)
  const [availProductOpen, setAvailProductOpen] = useState<boolean>(false)

	// LINEPLAN PDF
	const [lineplanPdfViewOpen, setLineplanPdfViewOpen] = useState<boolean>(false)
	const [selectedItemName, setSelectedItemName] = useState<string>('')

  useEffectApi(() => {

    document.title = `${typeName} | RotoEdgePro`

    getLineplanPageData(id, (data: any) => {
      setProductivityEnabled(data.productivity_enabled)
      setShifts(data.shifts)
      setScrapCodeList(data.scrap_codes)
      setLoading(false)
      setCanViewProductivity(data.canViewProductivity)
      setCanViewProduction(data.canViewProduction)
      setCanLogProduction(data.canLogProduction)
      setCanViewChannel(data.canViewChannel)

      setTitle(data.lpName)
      document.title = `${typeName} | ${data.lpCode} | RotoEdgePro`
    })

  }, [type, id])


	// LINE PLAN PDF VIEWING
	const viewSetupsheet = (itemName: string) => {
		setLineplanPdfViewOpen(true)
		setSelectedItemName(itemName)
	}
	const closeLineplanViewHandler = () => setLineplanPdfViewOpen(false)

  // production logging
  const logProductionModal = (orderId: number | undefined, logType: number) => {
    setLogType(logType)
    setLogNCOnly(false)
		
    if (orderId)
      getLogData(id, orderId, type, (data: any) => {
        setLogProductionItem([data])
      })
  }
  const logProductionModalNC = (childId: number | undefined) => {
    setLogType(LOG)
    setLogNCOnly(true)

    if (childId)
      getChildItemLogData(id, childId, (data: any) => {
        setLogProductionItem([data])
      })
  }
  const closeLogProduction = () => {
    setLogProductionItem([])
    setLogProductionErrors([])
  }
  const doLogProduction = (data: any) => {

    // remap logged production data to easier format for backend to read, comes in this form to handle the
    // logging production on multiple items at once at the molding step.

    logProduction(id, logNCOnly, {...data, type: type}, (data: any) => {
      console.log('log prod data :', data)
      if (data.errors)
        setLogProductionErrors(data.errors)
      else {
        closeLogProduction()
        setProductivityRefresh(!productivityRefresh)
        setSuppressLogLoad(true)
        setRefreshLog(!refreshLog)
        if (logType === LINE)
          setRefreshLine(!refreshLine)

        setTimeout(() => {
          for (const label of data.labelData) {
            console.log('print label :', label)
            printLabel(label.type, label.data, label.count)
          }
        }, 500)

      }
    })
  }
  // production viewing
  const viewProduction = (id: number | undefined) => {
    setProductionLogsOrderId(id)
    setProductionLogsOpen(true)
  }
  const openStageToLineplan = (id: number | undefined) => setStageToLineplanId(id)
  const closeStageToLineplan = () => setStageToLineplanId(undefined)
  // process instructions
  const viewProcessInstructions = (id: number | undefined) => {
    console.log('viewProcessInstructions :', id)
    if (id)
      getProcessInstructions(id, (data: any) => {
        setProcessInstructionsItemId(id)
        setProcessInstructionsPdf(data)
      })
  }
  const closeProcessInstructions = () => {
    setProcessInstructionsItemId(undefined)
    setProcessInstructionsPdf(undefined)
  }
  // channel
  const openChannel = () => {
    console.log(`openChannel=${id}`)
    setChannelId(id)
  }
  const closeChannel = () => setChannelId(undefined)
  const doRefreshChannel = () => {
    setRefreshChannel(!refreshChannel)
  }
  // bom pull (assembly only)
  const doPullBom = (orderID: number | undefined, orderQty?: number) => {
    if (id)
      getPullBomData(id, orderID, (data: any) => {
        generateBomPdf(data, orderQty)
      })
  }
  // 24hr production logs
  const openProductionLogs = () => {
    setProductionLogsOpen(true)
  }
  const closeProductionLogs = () => {
    setProductionLogsOrderId(undefined)
    setProductionLogsOpen(false)
  }

  // helpers
  const generateActions = () => {
    const actions: any = []

    if (canViewProduction)
      actions.push({text: 'Production Logs', action: openProductionLogs, icon: <LibraryBooks />})

    if (canViewChannel)
      actions.push({text: 'Open Channel', action: openChannel, icon: <Message />})

    return actions
  }
  const handleAvailProductExpand = (e: any, expanded: boolean) => setAvailProductOpen(expanded)

  return (
    <>
      <PageTitle title={`${typeName}: ${title}`} legend={[
      ]} />
      <BaseContent loading={loading}>

        <Grid container spacing={2}>

          <Grid item xs={12}>
            <ProcessingUserActions productivityEnabled={productivityEnabled} lineplanId={id} canView={canViewProductivity}
                                   forceRefresh={productivityRefresh} />
          </Grid>

          {canViewChannel ?
            <Grid item xs={12}>
              <Channel linePlanId={id} forceRefresh={refreshChannel} />
            </Grid>
            :
            <></>
          }

        </Grid>


        <LineplanLiveTable id={id} refresh={refreshLine} refreshable logProduction={logProductionModal} viewSetupsheet={viewSetupsheet} pullBom={type === ASSEMBLY_LP ? doPullBom : undefined}
                           actions={generateActions()} canLog={canLogProduction} doLoad />

        <BaseAccordion title='Available Product' onChange={handleAvailProductExpand}>
          <LineplanLogTable id={id} type={type} logProduction={logProductionModal} viewProduction={viewProduction}
                            stageToLineplan={openStageToLineplan} viewProcessInstructions={viewProcessInstructions}
                            shifts={shifts} refresh={refreshLog} suppressLoad={suppressLogLoad} logProductionNC={logProductionModalNC}
                            canViewProduction={canViewProduction} doLoad={availProductOpen}
                            pullBom={type === ASSEMBLY_LP ? doPullBom : undefined} />
        </BaseAccordion>

      </BaseContent>

      {/* modals */}
      <PdfViewer file={processInstructionsPdf} open={processInstructionsPdf !== undefined}
                 closeHandler={closeProcessInstructions} itemId={processInstructionsItemId} />
      <LogProduction data={logProductionItem} onClose={closeLogProduction} onLog={doLogProduction}
                     errors={logProductionErrors} scrapCodeList={scrapCodeList} nonConforming={type !== ASSEMBLY_LP}
                     ncOnly={logNCOnly} />
			<LineplanPdfView
				open={lineplanPdfViewOpen} close={closeLineplanViewHandler} 
				url={'/manufacturing/setupsheets/'} itemName={selectedItemName} bomItems={[]} printLoading={false}
				fullscreen={true}
			/>
      {canViewChannel ? <ChannelModal linePlanId={channelId} channelClose={closeChannel} reloadChannelDisplay={doRefreshChannel} /> : <></>}
      {canViewProduction ?
        <ProductionLogModal open={productionLogsOpen} type={logTypeFromLineType(type)} onClose={closeProductionLogs}
                            lineplanId={id} orderId={productionLogsOrderId} />
        :
        <></>
      }
      <ScheduleToLineplan id={stageToLineplanId} closeHandler={closeStageToLineplan} />
    </>
  )
}

export default LineplanProcessing
