import React, { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import Fade from '@mui/material/Fade' // Changed from Slide to Fade
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import TableCell from '@mui/material/TableCell'
import Remove from '@mui/icons-material/Remove'
import { colors } from '../../colors'
import { removeLoadAfter } from '../../../components/pages/scheduling/MachineScheduling/MachineSchedulingApi'
import { apiGet } from '../../api'
import BaseTooltip from '../BaseTooltip'
import { Box, Chip } from '@mui/material'

interface ItemCellProps {
	armId: number
	order?: any
	available: boolean
	availableAmount?: number
	availableMax?: number
	mode: string
	cellWidth: number
	removable?: boolean
	unloadOrderItem?: (name: string, id: number) => void
	onItemClick?: ((armId: number) => void) | undefined
	clickable?: boolean
}

const ItemCell: FC<ItemCellProps> = ({
	armId,
	order,
	available,
	availableAmount,
	availableMax,
	mode,
	cellWidth,
	removable,
	unloadOrderItem,
	onItemClick,
	clickable
}) => {
	const theme = useTheme();

	// colors remain the same...
	const leftoverCellColor: string = colors[mode].machineLoadDisplay.armLeftOverCellColor;
	const outlineColor = colors[mode].machineLoadDisplay.outlineColor;
	const cellColor = (balance: number) => {
		if (balance === 0) return theme.palette.color.red;
		if (balance <= 5) return theme.palette.color.orange;
		return theme.palette.color.green;
	}

	const getChipColor = (balance: number) => {
		return theme.palette.background.paper;
	}

	const canClick = () => {
		return onItemClick && (clickable === undefined || clickable);
	}

	let content;
	if (available) {
		content = (
			<Fade in={true} timeout={500}>
				<Box
					sx={{
						borderRadius: 3,
						border: 0,
						backgroundColor: leftoverCellColor,
						width: "100%",
						height: "100%",
						alignContent: 'center',
						boxShadow: 5
					}}
				>
					<Grid container justifyContent="center">
						<Grid item sx={{ paddingTop: 0, marginTop: 0 }}>
							{`${availableAmount} Available`}
						</Grid>
					</Grid>
				</Box>
			</Fade>
		);
	} else {
		content = (
			<Fade in={true} timeout={500}>
				<Box
					sx={{
						backgroundColor: cellColor(order.balance),
						width: "100%",
						textAlign: "center",
						cursor: canClick() ? "pointer" : "auto",
						borderRadius: 3,
						boxShadow: 5
					}}
					onClick={() => (canClick() && onItemClick ? onItemClick(armId) : {})}
				>
					<Grid container spacing={0} justifyContent="center">
						<Grid item xs={12} sx={{ pt: 1, fontWeight: "bold" }}>
							{order.item}
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								px: 1,
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
							}}
						>
							<Typography
								noWrap
								title={order.description}
								sx={{
									width: '100%',
									display: 'block',
									fontSize: 12
								}}
							>
								{order.description}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography noWrap>
								{order.number}
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								mb: 2,
								px: 3.5,
								display: 'flex',
								justifyContent: 'space-evenly',
								alignItems: 'center',
								gap: 0,
							}}
						>
							{removable ? (
								<BaseTooltip text="Unload order item">
									<IconButton
										size="medium"
										edge="start"
										color="secondary"
										aria-label="menu"
										sx={{
											mr: 1,
											color: "red",
											border: 1,
											boxShadow: 5,
											flexShrink: 0,
											backgroundColor: (theme) => {
												const baseColor = cellColor(order.balance);
												return theme.palette.augmentColor({
													color: { main: baseColor }
												}).light;
											}
										}}
										onClick={() => {
											if (unloadOrderItem)
												return unloadOrderItem(
													`${order.number} ${order.item}`,
													order.id
												);
										}}
									>
										<Remove sx={{ fontSize: "lg" }} />
									</IconButton>
								</BaseTooltip>
							) : <Box sx={{ width: 40, flexShrink: 0 }} />}
							<Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', }}>
								<Chip
									label={`Balance: ${order.balance}`}
									variant="outlined"
									size="small"
									sx={{
										fontSize: 14,
										bgcolor: getChipColor(order.balance),
										'& .MuiChip-label': {
											px: 1
										},
										fontWeight: "bold"
									}}
								/>
							</Box>
							<Box sx={{ width: 40, flexShrink: 0 }} />
						</Grid>
					</Grid>
				</Box>
			</Fade>
		);
	}

	return content;
}

interface OrderToLoadCellProps {
	machineLoadedOrderId: number
}

const OrderToLoadCell: FC<OrderToLoadCellProps> = ({ machineLoadedOrderId }) => {
	const [ordersToLoadList, setOrdersToLoadList] = useState<any[]>([])
	const outlineColor = colors[useTheme().palette.mode].machineLoadDisplay.outlineColor

	const doRemoveLoadAfter = (machineLoadedOrderId: number, orderToRemoveId: number) => {
		removeLoadAfter(orderToRemoveId, machineLoadedOrderId, (removeId: number) => {
			console.log('do remove load after callback id :', removeId)
			setOrdersToLoadList([...ordersToLoadList].filter((order: any) => order.id !== removeId))
		})
	}

	useEffect(() => {
		let isActive = true

		apiGet(`/scheduling/enhanced-loadqueue/${machineLoadedOrderId}/`, { getOrdersToLoad: true }, (resp) => {
			setOrdersToLoadList(resp.data.orders_to_load)
		})

		return () => { isActive = false }
	}, [machineLoadedOrderId])

	if (ordersToLoadList.length > 0)
		return (
			<Fade in={true} timeout={500}>
				<td style={{ margin: '5px' }}>
					<Grid container justifyContent='center' style={{ outline: `1px solid ${outlineColor}` }}>
						<Grid item xs={12} justifyContent='center'>
							<Typography sx={{ textAlign: 'center', mt: '4px', fontWeight: 'bold' }}>Load After Queue</Typography>
						</Grid>
						{ordersToLoadList.map((orderToLoad: any, key: number) => (
							<React.Fragment key={key}>
								<Grid item xs={12} sx={{ textAlign: 'center' }}>
									{orderToLoad.name}
									<IconButton size='small' sx={{ color: 'red' }} onClick={() => doRemoveLoadAfter(machineLoadedOrderId, orderToLoad.id)}>
										<Remove />
									</IconButton>
								</Grid>
							</React.Fragment>
						))}
					</Grid>
				</td>
			</Fade>
		)
	else
		return <></>
}

export {
	ItemCell, OrderToLoadCell
}